
export default {
    app_info: {
        app_name: "under-construction-page",
        app_id: "9a49c45f-48b8-4e31-9d8f-a226868b6877",
        app_title: "Under Construction Page",
        app_icon: "https://static.wixstatic.com/media/6cc087_c165ca6e01574650a7e8e6a722526e8e~mv2.png/v1/fill/w_54,h_54,al_c,q_85,usm_0.66_1.00_0.01/6cc087_c165ca6e01574650a7e8e6a722526e8e~mv2.webp",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/8975413-how-to-use-under-construction-coming-soon-page",
        review_url: "https://www.wix.com/app-market/add-review/9a49c45f-48b8-4e31-9d8f-a226868b6877",
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "The Under Construction Page  widget helps you engage your audience before your site launches. Choose from 11 customizable templates to create a visually striking Coming Soon page. Features include a dynamic countdown timer to build anticipation, customizable email capture forms for growing your audience, and social media integration to expand your presence. Tailor every detail to align with your brand, keeping visitors excited and connected until your grand reveal.",
        url: "https://help.marketpushapps.com/en/articles/8975413-how-to-use-under-construction-coming-soon-page",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
            collection_name: "Getting Started",
            articles: [
                {
                    title: "1. How to Under Construction Page",
                    url: "https://help.marketpushapps.com/en/articles/8975413-how-to-use-under-construction-coming-soon-page#h_c4c653edbd",
                },
                {
                    title: "2. How to make it responsive",
                    url: "https://help.marketpushapps.com/en/articles/8975413-how-to-use-under-construction-coming-soon-page#h_764a0a8e5e",
                },
                {
                    title: "3. How to change the app template",
                    url: "https://help.marketpushapps.com/en/articles/8975413-how-to-use-under-construction-coming-soon-page#h_9faee8aff9",
                },
                {
                    title: "4. How to add your own image within a template",
                    url: "https://help.marketpushapps.com/en/articles/8975413-how-to-use-under-construction-coming-soon-page#h_3e85ea685b",
                },
                {
                    title: "5. How to add my own countdown date",
                    url: "https://help.marketpushapps.com/en/articles/8975413-how-to-use-under-construction-coming-soon-page#h_fca648ef41",
                },
                {
                    title: "6. What happens when the countdown date is reached",
                    url: "https://help.marketpushapps.com/en/articles/8975413-how-to-use-under-construction-coming-soon-page#h_54310befa4",
                },
                {
                    title: "7. How to customize Whatsapp icon to open a conversation with my own phone number",
                    url: "https://help.marketpushapps.com/en/articles/8975413-how-to-use-under-construction-coming-soon-page#h_c988393985",
                },
            ],
        },

        ],
    },

    tutorial_videos: {
        show: false,
        videos: [{
            title: "How to use Under Construction Page",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
            url: "https://www.youtube.com/watch?v=123456",
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Under Construction Page",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};