export default {
    app_info: {
        app_name: "product_faq",
        app_id: "8ff8ff14-f9ee-4d1a-84ea-7c33c0ba9f36",
        app_title: "Product FAQ",
        app_icon: "https://static.wixstatic.com/media/0904c3_26db9fc281714a53a8dddc025caff020~mv2.png",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/10130754-how-to-use-product-faq", 
        review_url: "https://www.wix.com/app-market/add-review/8ff8ff14-f9ee-4d1a-84ea-7c33c0ba9f36",
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "Product FAQ makes it easy to add and manage FAQs for each individual product on your website. Choose from four unique templates to showcase FAQs in a style that suits your brand, helping customers find answers quickly and effortlessly.",
        url: "https://help.marketpushapps.com/en/articles/10130754-how-to-use-product-faq",
    },

    tutorial_articles: {
        show: false,
        articles_collections: [{
                collection_name: "Getting Started",
                articles: [
                    {
                        title: "1. How to add the app & change the template?",
                        url: "https://help.marketpushapps.com/en/articles/10130754-how-to-use-product-faq#h_a863a25f52",
                    },
                    {
                        title: "2. What are the available templates?",
                        url: "https://help.marketpushapps.com/en/articles/10130754-how-to-use-product-faq#h_6cd334d305",
                    },
                    {
                        title: "3. Can my customers add a review?",
                        url: "https://help.marketpushapps.com/en/articles/10130754-how-to-use-product-faq#h_790db58c2a",
                    },
                    {
                        title: "4. How to modify the content?",
                        url: "https://help.marketpushapps.com/en/articles/10130754-how-to-use-product-faq#h_74a006b1e6",
                    },
                    {
                        title: "4. How to add/or remove items",
                        url: "https://help.marketpushapps.com/en/articles/10130754-how-to-use-product-faq#h_cd9e7a2422",
                    },
                ],
            },
        ],
    },

    tutorial_videos: {
        show: false, 
        videos: [{
            title: "How to use Product FAQ",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png", 
            url: "https://www.youtube.com/watch?v=123456", 
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Product FAQ",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};