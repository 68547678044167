

export default {
    app_info: {
        app_name: "sales_countdown_timer",
        app_id: "51078f3d-f607-4c47-85bf-857b024e0895",
        app_title: "Promotion Countdown Timer",
        app_icon: "https://static.wixstatic.com/media/19e924_d9d2f78be6bb45709e8744da658350ab~mv2.jpg", 
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/9053728-how-to-use-sale-countdown-timer",
        review_url: "https://www.wix.com/app-market/add-review/51078f3d-f607-4c47-85bf-857b024e0895",
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "The 'Sales Countdown Timer' app offers 46 customizable templates for 14 major holidays, including Easter, Black Friday, and New Year, plus general sales and restaurant themes. Quick setup allows seamless alignment with your site's style. Dual-state functionality engages visitors by switching messages post-countdown, while integrated coupons drive urgency and conversions. Perfect for enhancing sales campaigns, the app ensures impactful, engaging, and personalized promotions for every holiday event.",
        url: "https://help.marketpushapps.com/en/articles/9053728-how-to-use-sale-countdown-timer",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
                collection_name: "Getting Started",
                articles: [
                    {
                        title: "1. How to add Event Countdown Timer",
                        url: "https://help.marketpushapps.com/en/articles/9053728-how-to-use-sale-countdown-timer#h_44a30f0a2a",
                    },
                    {
                        title: "2. How to change the Template",
                        url: "https://help.marketpushapps.com/en/articles/9053728-how-to-use-sale-countdown-timer#h_69b8f05e6c",
                    },
                    {
                        title: "3. How to change an Image",
                        url: "https://help.marketpushapps.com/en/articles/9053728-how-to-use-sale-countdown-timer#h_54f06aea11",
                    },
                    {
                        title: "4. How to change Color & Style",
                        url: "https://help.marketpushapps.com/en/articles/9053728-how-to-use-sale-countdown-timer#h_4bad4e5316",
                    },
                    {
                        title: "5. How to change the counter date",
                        url: "https://help.marketpushapps.com/en/articles/9053728-how-to-use-sale-countdown-timer#h_d66827f21e",
                    },
                    {
                        title: "6. What happens at end of the counter when it reaches 00:00:00:00 and how to customise it",
                        url: "https://help.marketpushapps.com/en/articles/9053728-how-to-use-sale-countdown-timer#h_3f43b02ddd",
                    },

                ],
            },
        ],
    },

    tutorial_videos: {
        show: true,
        videos: [{
            title: "How to use Promotion Countdown Timer",
            thumbnail: "https://img.youtube.com/vi/bZ94yGNvOBM/maxresdefault.jpg",
            url: "https://www.youtube.com/watch?v=bZ94yGNvOBM",
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Sales Countdown Timer",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};