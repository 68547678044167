
export default {
  app_info: {
    app_name: "mkp-dev-recently-viewed",
    app_id: "cb42d177-ec38-4067-8224-d4107d4bb56c",
    app_title: "Recently Viewed Products",
    app_icon:
      "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/recently-viewed-products-js-snippet/Logo/Logo.png",
  },

  main_links: {
    faq_url:
      "https://help.marketpushapps.com/en/articles/9944402-how-to-use-recently-viewed-products",
    review_url:
      "https://www.wix.com/app-market/add-review/cb42d177-ec38-4067-8224-d4107d4bb56c",
  },

  storage: {
    collection_name: "mkp-dev-recently-viewed",
  },

  tutorial_main: {
    show: true,
    title: "📘 Getting Started Guide",
    description:
      "The Recently Viewed Products Widget helps users easily revisit products they've explored on your site. It automatically tracks and displays items based on user interactions, providing a seamless shopping experience. Customize the layout, display settings, and product details to match your website's design. Follow the guide to set up and optimize this engaging and user-friendly widget.",
    url: "https://help.marketpushapps.com/en/articles/9944402-how-to-use-recently-viewed-products",
  },

  tutorial_articles: {
    show: true,
    articles_collections: [
      {
        collection_name: "How to Recently Viewed Products",
        articles: [
          {
            title: "1. How to add Recently Viewed Products as a (plug-in) on the Product Page.",
            url: "https://help.marketpushapps.com/en/articles/9944402-how-to-use-recently-viewed-products#h_a177130c9d",
          },
          {
            title: " 2. How to add Recently Viewed Products as a Widget anywhere on your site",
            url: "https://help.marketpushapps.com/en/articles/9944402-how-to-use-recently-viewed-products#h_391d9e5e9a",
          },
          {
            title: "3. How to access the widget Dashboard.",
            url: "https://help.marketpushapps.com/en/articles/9944402-how-to-use-recently-viewed-products#h_11244c03db",
          },
          {
            title: "4. Save and Publish your site.",
            url: "https://help.marketpushapps.com/en/articles/9944402-how-to-use-recently-viewed-products#h_5312d5491d",
          },
          {
            title: "5. How to get Additional Support.",
            url: "https://help.marketpushapps.com/en/articles/9944402-how-to-use-recently-viewed-products#h_4de4c02622",
          },
        ],
      },
    ],
  },

  tutorial_videos: {
    show: true,
    videos: [
      {
        title: "How to use Recently Viewed Products",
        thumbnail: "https://img.youtube.com/vi/Ys10jDF8TRk/maxresdefault.jpg",
        url: "https://www.youtube.com/watch?v=Ys10jDF8TRk",
      },
    ],
  },

  intercom_widget: {
    show: true,
    appName: "Recently Viewed Products",

    negative_feedback_options: [
      {
        title: "Features do not work for me",
        message:
          "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
      },
      {
        title: "App setup is too complicated",
        message:
          "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
      },
      {
        title: "Subscriptions are too expensive",
        message:
          "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
      },
      {
        title: "App has issues",
        message:
          "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
      },
      {
        title: "Other",
        message: "🥲 Feedback: I have a problem with --> ",
      },
    ],
  },
};
