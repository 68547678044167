
export default {
  app_info: {
    app_name: "frequently_bought_together",
    app_id: "a8fc7012-7598-498e-b517-1cda10b58336",
    app_title: "Frequently Bought Together",
    app_icon:
      "https://static.wixstatic.com/media/19e924_b9bc5672a0ba4714ac2caa7eaab63dee~mv2.png",
  },

  main_links: {
    faq_url:
      "https://help.marketpushapps.com/en/articles/10106362-frequently-bought-together",
    review_url:
      "https://www.wix.com/app-market/add-review/a8fc7012-7598-498e-b517-1cda10b58336",
  },

  storage: {
    collection_name: "frequently_bought_together",
  },

  tutorial_main: {
    show: true,
    title: "📘 Getting Started Guide",
    description:
      "Frequently Bought Together boosts sales by showcasing product bundles on your Product Pages. Use AI to auto-generate bundles or create them manually. Offer exclusive discounts to incentivize purchases, and let customers add all items with one click. The app includes a dashboard to track bundle performance and revenue impact",
    url: "https://help.marketpushapps.com/en/articles/10106362-frequently-bought-together",
  },

  tutorial_articles: {
    show: true,
    articles_collections: [
      {
        collection_name: "How to Use Frequently Bought Together",
        articles: [
          {
            title: "1. Add Frequently Bought Together to your website",
            url: "https://help.marketpushapps.com/en/articles/10106362-how-to-use-frequently-bought-together#h_ef276ce0c6",
          },
          {
            title: "2. How to create Frequently Bought Together Bundles",
            url: "https://help.marketpushapps.com/en/articles/10106362-how-to-use-frequently-bought-together#h_4416658a46",
          },
          {
            title: "3. How to customize your widget",
            url: "https://help.marketpushapps.com/en/articles/10106362-how-to-use-frequently-bought-together#h_592dc1dfb8",
          },
          {
            title: "4. How to get Additional Support",
            url: "https://help.marketpushapps.com/en/articles/10106362-how-to-use-frequently-bought-together#h_727c3b2388",
          },
        ],
      },
    ],
  },

  tutorial_videos: {
    show: true,
    videos: [
      {
        title: "How to use Frequently Bought Together",
        thumbnail: "https://img.youtube.com/vi/zTCIemmRuwk/maxresdefault.jpg",
        url: "https://www.youtube.com/watch?v=zTCIemmRuwk",
      },
    ],
  },

  intercom_widget: {
    show: true,
    appName: "Frequently Bought Together",

    negative_feedback_options: [
      {
        title: "Features do not work for me",
        message:
          "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
      },
      {
        title: "App setup is too complicated",
        message:
          "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
      },
      {
        title: "Subscriptions are too expensive",
        message:
          "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
      },
      {
        title: "App has issues",
        message:
          "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
      },
      {
        title: "Other",
        message: "🥲 Feedback: I have a problem with --> ",
      },
    ],
  },
};
