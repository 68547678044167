
export default {
    app_info: {
      app_name: "google_review_pro",
      app_id: "f20b0377-bc3e-418d-9d73-2ac147543483",
      app_title: "Google Reviews PRO",

      app_icon:
        "https://static.wixstatic.com/media/19e924_cd6d894baca443ccaf90e7c854646be0~mv2.png",
    },
  
    main_links: {
      faq_url:
        "https://help.marketpushapps.com/en/collections/7329912-google-reviews-pro",
      review_url:
        "https://www.wix.com/app-market/add-review/f20b0377-bc3e-418d-9d73-2ac147543483",
    },
  
    storage: {
      collection_name: "google_review_pro",
    },
  
    tutorial_main: {
      show: true,
      title: "📘 Getting Started Guide",
      description:
        "Google Reviews PRO allows seamless integration of authentic customer feedback directly onto your website, enhancing trust and conversions. Easily connect your Google Business location and import reviews effortlessly. Customize the display with various templates and layouts to match your site’s design. Showcase positive testimonials as persuasive social proof to attract visitors and boost engagement. Strengthen your online reputation and differentiate your business with this powerful and user-friendly review management tool.",
      url: "https://help.marketpushapps.com/en/collections/7329912-google-reviews-proe",
    },
  
    tutorial_articles: {
      show: true,
      articles_collections: [
        {
          collection_name: "How to Use Google Reviews PRO",
          articles: [
            {
              title: "App Doesn't open",
              url: "https://help.marketpushapps.com/en/articles/8690746-app-doesn-t-open",
            },
            {
              title: "How to use Google Reviews Pro",
              url: "https://help.marketpushapps.com/en/articles/8814882-how-to-use-google-reviews-pro",
            },
            {
              title: "My Google Business page is not connecting.",
              url: "https://help.marketpushapps.com/en/articles/8690734-my-google-business-page-is-not-connecting",
            },
            {
              title: "How can I optimise for mobile?",
              url: "https://help.marketpushapps.com/en/articles/8690783-how-can-i-optimise-for-mobile",
            },
            {
                title: "Is there a way to embed the widget so it doesn't scroll?",
                url: "https://help.marketpushapps.com/en/articles/8690787-is-there-a-way-to-embed-the-widget-so-it-doesn-t-scroll",
              },
              {
                title: "How to change widget position",
                url: "https://help.marketpushapps.com/en/articles/8699804-how-to-change-widget-position",
              },
              {
                title: "How to add a link to your Google Listing within the widget",
                url: "https://help.marketpushapps.com/en/articles/8699759-how-to-add-a-link-to-your-google-listing-within-the-widget",
              },
              {
                title: "I don't have a Google Business Profile page",
                url: "https://help.marketpushapps.com/en/articles/8713156-i-don-t-have-a-google-business-profile-page",
              },
              {
                title: "Can I Connect Multiple Businesses?",
                url: "https://help.marketpushapps.com/en/articles/8742751-can-i-connect-multiple-businesses",
              },
          ],
        },
      ],
    },
  
    tutorial_videos: {
      show: true,
      videos: [
        {
          title: "How to use Google Reviews PRO",
          thumbnail: "https://img.youtube.com/vi/hXPja3L5Byk/maxresdefault.jpg",
          url: "https://www.youtube.com/watch?v=hXPja3L5Byk",
        },
      ],
    },
  
    intercom_widget: {
      show: true,
      appName: "Google Reviews PRO",
  
      negative_feedback_options: [
        {
          title: "Features do not work for me",
          message:
            "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
        {
          title: "App setup is too complicated",
          message:
            "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
        },
        {
          title: "Subscriptions are too expensive",
          message:
            "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
        },
        {
          title: "App has issues",
          message:
            "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
        },
        {
          title: "Other",
          message: "🥲 Feedback: I have a problem with --> ",
        },
      ],
    },
  };
  