export default {
    app_info: {
        app_name: "pinterest_feed_sync",
        app_id: "78b324a9-562c-40a7-880d-b8ecc1c2ab99",
        app_title: "Pinterest Feed Sync",
        app_icon: "https://static.wixstatic.com/media/33caa7_82654cd013ae4fafa6bf27ad69f7f257~mv2.png",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/8713911-how-to-use-pinterest-feed-sync-app",
        review_url: "https://www.wix.com/app-market/add-review/78b324a9-562c-40a7-880d-b8ecc1c2ab99",
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "Pinterest Feed Sync helps seamlessly connect your store to Pinterest, automatically converting your products into Pins. Any updates, including product changes and variants, sync effortlessly, ensuring your Pinterest page stays up to date. The app generates a Pinterest-compatible CSV feed, making integration simple. Video tutorials guide beginners through setup, while advanced features support complex listings. Expand your store’s reach, attract new customers, and grow your business with Pinterest automation.",
        url: "https://help.marketpushapps.com/en/articles/8713911-how-to-use-pinterest-feed-sync-app",
    },

    tutorial_articles: {
        show: false,
        articles_collections: [{
            collection_name: "Getting Started",
            articles: [
                {
                    title: "1. How to add the app & change the template?",
                    url: "#h_a863a25f52",
                },
                {
                    title: "2. What are the available templates?",
                    url: "#h_6cd334d305",
                },
                {
                    title: "3. Can my customers add a review?",
                    url: "#h_790db58c2a",
                },
                {
                    title: "4. How to modify the content?",
                    url: "#h_74a006b1e6",
                },
                {
                    title: "4. How to add/or remove items",
                    url: "#h_cd9e7a2422",
                },
            ],
        },
        ],
    },

    tutorial_videos: {
        show: true, 
        videos: [{
            title: "How to use Pinterest Feed Sync",
            thumbnail: "https://img.youtube.com/vi/jZ7PdYNa62c/maxresdefault.jpg", 
            url: "https://www.youtube.com/watch?v=jZ7PdYNa62c", 
        },],
    },

    intercom_widget: {
        show: true,
        appName: "Pinterest Feed Sync",

        negative_feedback_options: [{
            title: "Features do not work for me",
            message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
        {
            title: "App setup is too complicated",
            message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
        },
        {
            title: "Subscriptions are too expensive",
            message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
        },
        {
            title: "App has issues",
            message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
        },
        {
            title: "Other",
            message: "🥲 Feedback: I have a problem with --> ",
        },
        ],
    },
};