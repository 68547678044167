
export default {
    app_info: {
        app_name: "airbnb_reviews",
        app_id: "2f3bd9eb-c83b-4eab-ac67-0ec4c99ddb12",
        app_title: "Airbnb Reviews",
        app_icon: "https://static.wixstatic.com/media/19e924_dcb709b457d14593b533a1ee73ea7ed3~mv2.png/v1/fill/w_108,h_108,al_c,q_85,usm_0.66_1.00_0.01/19e924_dcb709b457d14593b533a1ee73ea7ed3~mv2.webp",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/collections/7329922-airbnb-reviews", 
        review_url: "https://www.wix.com/app-market/add-review/2f3bd9eb-c83b-4eab-ac67-0ec4c99ddb12",
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "Easily import and display Airbnb reviews on your website to enhance your property’s credibility and attract more bookings. With customizable review widgets, you can match the design to your website’s aesthetics, choosing from various templates. Automatically update positive guest reviews without manual effort, saving time and boosting your listing’s appeal. By showcasing genuine testimonials, you build trust with potential guests, increase confidence, and stand out from competitors. Leverage social proof to maximize bookings and occupancy rates effortlessly.",
        url: "https://help.marketpushapps.com/en/articles/8702008-how-to-use-airbnb-reviews-app",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
            collection_name: "Getting Started",
            articles: [
                {
                    title: "1. How to use Airbnb Reviews App?",
                    url: "https://help.marketpushapps.com/en/articles/8702008-how-to-use-airbnb-reviews-app",
                },
                {
                    title: "2. How to change widget position?",
                    url: "https://help.marketpushapps.com/en/articles/8713290-how-to-change-widget-position",
                },
                {
                    title: "3. How to add a link to your Airbnb Listing within the widget?",
                    url: "https://help.marketpushapps.com/en/articles/8713865-how-to-add-a-link-to-your-airbnb-listing-within-the-widget",
                },
            ],
        },
        {
            collection_name: "Help Articles",
            articles: [
                {
                    title: "I don't have an Airbnb Page",
                    url: "https://help.marketpushapps.com/en/articles/8713012-i-don-t-have-an-airbnb-page",
                },
                {
                    title: "Is there a way to embed the widget so it doesn't scroll?",
                    url: "https://help.marketpushapps.com/en/articles/8713269-is-there-a-way-to-embed-the-widget-so-it-doesn-t-scroll",
                },
                {
                    title: "Can I add an Airbnb Experience?",
                    url: "https://help.marketpushapps.com/en/articles/8715649-can-i-add-an-airbnb-experience",
                },
            ],
        },
        ],
    },

    tutorial_videos: {
        show: true,
        videos: [{
            title: "How to use Airbnb Reviews",
            thumbnail: "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/ask-for-review-assets/airbnb_yt_thumbnail.png", 
            url: "https://www.youtube.com/watch?v=7p1U6whIaL4",
        },],
    },

    intercom_widget: {
        show: true,
        appName: "Airbnb Reviews",

        negative_feedback_options: [{
            title: "Features do not work for me",
            message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};