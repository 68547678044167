export default {
    app_info: {
        app_name: "linkedin_profile_embed",
        app_id: "dd3d8693-c945-4dfd-b865-57505078ec4f",
        app_title: "LinkedIn Profile Embed",
        app_icon: "https://static.wixstatic.com/media/6cc087_119eddf2a229429fa3ab9d9a553f1cd2~mv2.png",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/10482358-how-to-use-linkedin-profile",
        review_url: "https://www.wix.com/app-market/add-review/dd3d8693-c945-4dfd-b865-57505078ec4f",
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "Effortlessly integrate the LinkedIn profile widget into your website with the LinkedIn Profile app. Customize the size, theme, and type to align seamlessly with your website's design while preserving LinkedIn's professional aesthetic. Whether you're showcasing a personal profile or a company page, this app ensures an elegant and user-friendly presentation.",
        url: "https://help.marketpushapps.com/en/articles/10482358-how-to-use-linkedin-profile",
    },

    tutorial_articles: {
        show: false,
        articles_collections: [{
            collection_name: "Getting Started",

            articles: [
                {
                    title: "1. How to add the app & change the template?",
                    url: "#h_a863a25f52",
                },
                {
                    title: "2. What are the available templates?",
                    url: "#h_6cd334d305",
                },
                {
                    title: "3. Can my customers add a review?",
                    url: "#h_790db58c2a",
                },
                {
                    title: "4. How to modify the content?",
                    url: "#h_74a006b1e6",
                },
                {
                    title: "4. How to add/or remove items",
                    url: "#h_cd9e7a2422",
                },
            ],
        },
        ],
    },

    tutorial_videos: {
        show: false, 
        videos: [{
            title: "How to use LinkedIn Profile Embed",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png", 
            url: "https://www.youtube.com/watch?v=123456", 
        },],
    },

    intercom_widget: {
        show: true,
        appName: "LinkedIn Profile Embed",

        negative_feedback_options: [{
            title: "Features do not work for me",
            message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
        {
            title: "App setup is too complicated",
            message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
        },
        {
            title: "Subscriptions are too expensive",
            message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
        },
        {
            title: "App has issues",
            message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
        },
        {
            title: "Other",
            message: "🥲 Feedback: I have a problem with --> ",
        },
        ],
    },
};