export default {
    app_info: {
        app_name: "logo_gallery",
        app_id: "d2043286-f903-4d1d-8f00-eaf98e4021ea",
        app_title: "Logo Gallery",
        app_icon: "https://static.wixstatic.com/media/19e924_5b15730119724c53af38584398f2bde5~mv2.png",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/10019618-how-to-use-logo-showcase",
        review_url: "https://www.wix.com/app-market/add-review/d2043286-f903-4d1d-8f00-eaf98e4021ea",
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "Logo Gallery helps you display your brand logos in a professional, sleek way. Choose from ten layout options, allowing you to showcase your logos attractively. You can use multiple widgets and display any type of image, including vector art and more. Simple to use, with customizable elements in each template, ensuring your logos are presented exactly how you want them.",
        url: "https://help.marketpushapps.com/en/articles/10019618-how-to-use-logo-showcase",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
                collection_name: "Getting Started",
                articles: [
                    {
                        title: "1. How to add Logo gallery to your site",
                        url: "https://help.marketpushapps.com/en/articles/10019618-how-to-use-logo-gallery#h_16cf6ca12f",
                    },
                    {
                        title: "2. How to make the widget responsive",
                        url: "https://help.marketpushapps.com/en/articles/10019618-how-to-use-logo-gallery#h_941d411386",
                    },
                    {
                        title: "3. Adding the CMS to Your Site (One-Time Setup) (Skip this step if you already have CMS added to your Site)",
                        url: "https://help.marketpushapps.com/en/articles/10019618-how-to-use-logo-gallery#h_809723a291",
                    },
                    {
                        title: "4. How to change collection data",
                        url: "https://help.marketpushapps.com/en/articles/10019618-how-to-use-logo-gallery#h_1d4b5bb7c9",
                    },
                    {
                        title: "5. How to use multiple widgets in a site",
                        url: "https://help.marketpushapps.com/en/articles/10019618-how-to-use-logo-gallery#h_a5579eb609",
                    },
                    {
                        title: "6. How to add more logos to the collection",
                        url: "https://help.marketpushapps.com/en/articles/10019618-how-to-use-logo-gallery#h_194395eafd",
                    },
                    {
                        title: "7. How to remove existing logos in the collection",
                        url: "https://help.marketpushapps.com/en/articles/10019618-how-to-use-logo-gallery#h_1581f64a4a",
                    },
                    {
                        title: "8. How to get additional support",
                        url: "https://help.marketpushapps.com/en/articles/10019618-how-to-use-logo-gallery#h_ce7cc3a299",
                    },
                ],
            },
        ],
    },

    tutorial_videos: {
        show: false, 
        videos: [{
            title: "How to use Logo Gallery",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png", 
            url: "https://www.youtube.com/watch?v=123456", 
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Logo Gallery",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};