
export default {
  app_info: {
    app_name: "image_hotspots",
    app_id: "f0e35f20-e8a0-44f4-942a-88ea20eaa779",
    app_title: "Image Hotspots Pro",
    app_icon:
      "https://static.wixstatic.com/media/c7fe21_edad356bf77b492f9007ffb3cf17a5dc~mv2.png",
  },

  main_links: {
    faq_url:
      "https://help.marketpushapps.com/en/collections/8202753-image-hotspots",
    review_url:
      "https://www.wix.com/app-market/add-review/f0e35f20-e8a0-44f4-942a-88ea20eaa779",
  },

  storage: {
    collection_name: "mkp-dev-image-hotspots",
  },

  tutorial_main: {
    show: true,
    title: "📘 Getting started guide",
    description:
      "Image hotspots is a powerful tool to add interactive hotspots to your images. You can add images, videos, and HTML content to the hotspots. You can also customize the colors and the size of the hotspots. You can also add a popup message to the hotspots to display more information.",
    url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_b1bff97fc6",
  },

  tutorial_articles: {
    show: true,
    articles_collections: [
      {
        collection_name: "Help Articles",
        articles: [
          {
            title: "How to add colors without a color picker?",
            url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_e750f2ba12",
          },
          {
            title: "How to upload my own image?",
            url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_3eb4f36ee2",
          },
          {
            title: "How to add HTML content to Popup message?",
            url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_9e7bc726e1",
          },
          {
            title:
              "How can I Disable/Hide the popup message for a specific hotspot?",
            url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_5e7e7e3036",
          },
        ],
      },
    ],
  },

  tutorial_videos: {
    show: false,
    videos: [
      {
        title: "How to use image hotspots",
        thumbnail:
          "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/dev-fb-reviews-js-snippet/image-youtube-tut.png",
        description:
          "This video by Market Push Apps shows you how to easily display image hotspots app on your website. You can add images, videos, and HTML content to the hotspots. ",
        url: "https://www.youtube.com/watch?v=1234567",
      },
    ],
  },

  intercom_widget: {
    show: true,
    appName: "Image Hotspots",

    negative_feedback_options: [{
        title: "Features do not work for me",
        message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
      },
      {
        title: "App setup is too complicated",
        message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
      },
      {
        title: "Subscriptions are too expensive",
        message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
      },
      {
        title: "App has issues",
        message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
      },
      {
        title: "Other",
        message: "🥲 Feedback: I have a problem with --> ",
      },
    ],
  },
};
