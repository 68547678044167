// Import wix block apps
import countdown_event from "./apps/countdown_event";
import countdown_sales from "./apps/sales_countdown_timmer";
import countdown_wedding from "./apps/countdown_wedding";
import footer_templates from "./apps/footer_templates";
import google_sheets_cell_value from "./apps/google_sheets_cell_value";
import hero_templates from "./apps/hero_templates";
import horizontal_collapse_tabs from "./apps/horizontal_collapse_tabs";
import image_hotspots from "./apps/image_hotspots";
import number_counter from "./apps/number_counter";
import nutrition_label from "./apps/nutrition_label";
import products_counter from "./apps/products_counter";
import qr_code from "./apps/qr_code";
import restaurant_menu from "./apps/restaurant_menu";
import tabs_vertical_collapse from "./apps/tabs_vertical_collapse";
import tutorial from "./apps/tutorial";
import under_construction_page from "./apps/under_construction_page";
import vimeo_gallery from "./apps/vimeo_gallery";
import youtube_gallery from "./apps/youtube_gallery";
import price_list from "./apps/price_list";
import team_members from "./apps/team_members";
import bulk_add_to_cart from "./apps/bulk_add_to_cart";
import pet_adoption from "./apps/pet_adoption";
import loan_calculator from "./apps/loan_calculator";
import reviews_section from "./apps/reviews_section";
import facebook_gallery from "./apps/facebook_gallery";
import pie_chart from "./apps/pie_chart";
import donut_chart from "./apps/donut_chart";
import pricing_plans from "./apps/pricing_plans";
import line_chart from "./apps/line_chart";
import tiktok_feed from "./apps/tiktok_feed";
import area_chart from "./apps/area_chart";
import column_chart from "./apps/column_chart";
import bar_chart from "./apps/bar_chart";
import pricing_table from "./apps/pricing_table";
import funnel_chart from "./apps/funnel_chart";
import pyramid_chart from "./apps/pyramid_chart";
import timeline_chart from "./apps/timeline_chart";
import statistics_card from "./apps/statistics_card";
import tripadvisor_reviews from "./apps/tripadvisor_reviews";
import airbnb_reviews from "./apps/airbnb_reviews";
import booking_reviews from "./apps/booking_com_reviews";
import yelp_reviews from "./apps/yelp_reviews";
import facebook_reviews from "./apps/facebook_reviews";
import affiliate_builder from "./apps/affiliate_builder_pro";
import trust_badges from "./apps/trust_badges";
import recently_viewed_products from "./apps/recently_viewed";
import search_filter_gallery from "./apps/search_filter_gallery";
import staff_members from "./apps/staff_members";
import animation_section from "./apps/animation_section";
import logo_gallery from "./apps/logo_gallery";
import jotform_embed from "./apps/jotform_embed";
import typeform_embed from "./apps/typeform_embed";
import payment_methods from "./apps/payment_methods";
import product_pdf_and_file_downloads from "./apps/pdf_and_file_downloads";
import product_faq from "./apps/product_faq";
import bookings_products_cross_sell from "./apps/bookings_cross_sell";
import quantity_and_volume_discounts from "./apps/quantity_and_volume_discounts";
import review_badges from "./apps/review_badges";
import linkedin_profile from "./apps/linkedin_profile_embed";
import pinterest_board from "./apps/pinterest_board";
import request_quote from "./apps/request_quote";
import auction_store from "./apps/auction_store";
import buy_x_get_y_discount from "./apps/buy_x_get_y_discount";
import email_marketing_with_gmail from "./apps/email_marketing_with_gmail";
import frequently_bought_together from "./apps/frequently_bought_together";
import subscribe_form from "./apps/subscribe_form";
import buy_again_repeat_orders from "./apps/buy_again_repeat_orders";
import business_hours_open_sign from "./apps/business_hours_open_sign";
import marketplace_store from "./apps/marketplace_store";
import sms_blast from "./apps/sms_blast";
import social_media_share from "./apps/social_media_share_follow";
import age_verification from "./apps/age_verification_pro";
import vacation_mode from "./apps/vacation_mode";
import addblocker_detector from "./apps/addblocker_detector";
import expedia_reviews_widget from "./apps/expedia_reviews_widget";
import foursquare_reviews from "./apps/foursquare_reviews";
import google_shopping_feed from "./apps/google_shopping_by_mkp";
import airtable_connector from "./apps/airtable_connector";
import tiktok_catalog_sync from "./apps/tiktok_catalog_sync";
import whatsapp_notifications from "./apps/whatapp_notifications";
import bulk_coupon_generator from "./apps/bulk_coupon_generator";
import spotify_playlist_embed from "./apps/spotify_playlist_embed";
import calendly_embeded from "./apps/calendly_embeded";
import tiktok_embed from "./apps/tiktok_embed";
import google_sheets_table from "./apps/google_sheets_table";
import google_reviews_pro from "./apps/google_review_pro";
import pinterest_feed_sync from "./apps/pinterest_feed_sync";
import cart_recovery from "./apps/cart_recovery";
import google_calender from "./apps/google_calender";
import google_slides_embed from "./apps/google_slides_embed";
import google_form_embed from "./apps/google_form_embed";
import event_product_upsell from "./apps/events_products_upsell";
import eventbrite_ticket from "./apps/eventbrite_ticket_embed";
import icon_tabs from "./apps/icon_tabs";
import blogs_recipe from "./apps/blogs_recipe";
import size_charts from "./apps/size_charts";
import twitch_stream from "./apps/twitch_stream";
import blog_products_upsell from "./apps/blog_products_upsell";
import currency_switcher from "./apps/currency_switcher";
import paypal_payments_button from "./apps/paypal_payments_button";
import facebook_page from "./apps/facebook_page";
import download_product_as_pdf from "./apps/download_product_as_pdf";
import kickstarter from "./apps/kickstarter";
import copy_link_button from "./apps/copy_link_button"
import most_sold_products from "./apps/most_sold_products";
import shipping_protection from "./apps/shipping_protection";

const apps = {
  image_hotspots,
  qr_code,
  countdown_event,
  countdown_wedding,
  countdown_sales,
  restaurant_menu,
  horizontal_collapse_tabs,
  footer_templates,
  hero_templates,
  number_counter,
  products_counter,
  vimeo_gallery,
  tabs_vertical_collapse,
  under_construction_page,
  google_sheets_cell_value,
  tutorial,
  nutrition_label,
  youtube_gallery,
  price_list,
  team_members,
  bulk_add_to_cart,
  pet_adoption,
  loan_calculator,
  reviews_section,
  facebook_gallery,
  pie_chart,
  donut_chart,
  pricing_plans,
  line_chart,
  tiktok_feed,
  area_chart,
  column_chart,
  bar_chart,
  pricing_table,
  funnel_chart,
  pyramid_chart,
  timeline_chart,
  statistics_card,
  tripadvisor_reviews,
  airbnb_reviews,
  booking_reviews,
  yelp_reviews,
  facebook_reviews,
  affiliate_builder,
  trust_badges,
  recently_viewed_products,
  search_filter_gallery,
  staff_members,
  animation_section,
  logo_gallery,
  jotform_embed,
  typeform_embed,
  payment_methods,
  product_pdf_and_file_downloads,
  product_faq,
  bookings_products_cross_sell,
  quantity_and_volume_discounts,
  review_badges,
  linkedin_profile,
  pinterest_board,
  request_quote,
  auction_store,
  buy_x_get_y_discount,
  email_marketing_with_gmail,
  frequently_bought_together,
  subscribe_form,
  buy_again_repeat_orders,
  business_hours_open_sign,
  marketplace_store,
  sms_blast,
  social_media_share,
  age_verification,
  vacation_mode,
  addblocker_detector,
  expedia_reviews_widget,
  foursquare_reviews,
  google_shopping_feed,
  tiktok_catalog_sync,
  airtable_connector,
  whatsapp_notifications,
  bulk_coupon_generator,
  spotify_playlist_embed,
  calendly_embeded,
  tiktok_embed,
  google_sheets_table,
  google_reviews_pro,
  pinterest_feed_sync,
  cart_recovery,
  google_calender,
  google_slides_embed,
  google_form_embed,
  event_product_upsell,
  eventbrite_ticket,
  icon_tabs,
  blogs_recipe,
  size_charts,
  twitch_stream,
  blog_products_upsell,
  currency_switcher,
  paypal_payments_button,
  facebook_page,
  download_product_as_pdf,
  kickstarter,
  copy_link_button,
  most_sold_products,
  shipping_protection,



  // Add more wix apps as needed
  //Test site use http://localhost:8080/?instance=intercom_article&app={ App Name }&page=feedback-mood
};

export default apps;
