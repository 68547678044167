

export default {
    app_info: {
        app_name: "pricing_plans",
        app_id: "17954fc2-b398-4b06-b55f-35caef03cc44", 
        app_title: "Pricing Plans Templates",
        app_icon: "https://static.wixstatic.com/media/19e924_46e9aed69ba244a7867250026fecc0a4~mv2.jpg", 
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/9349878-how-to-use-pricing-plan-templates",
        review_url: "https://www.wix.com/app-market/add-review/17954fc2-b398-4b06-b55f-35caef03cc44", // TBD
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "Pricing Plans offers you different kinds of pricing plan templates. Choose from six templates with monthly and yearly options. Easy to use and keeps your pricing looking professional.",
        url: "https://help.marketpushapps.com/en/articles/9349878-how-to-use-pricing-plan-templates",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
                collection_name: "Getting Started",
                articles: [
                    {
                        title: "1. What is Pricing plan templates app?",
                        url: "https://help.marketpushapps.com/en/articles/9349878-how-to-use-pricing-plan-templates#h_b219c8aa4d",
                    },
                    {
                        title: "2. How to change the Template",
                        url: "https://help.marketpushapps.com/en/articles/9349878-how-to-use-pricing-plan-templates#h_207d24514c",
                    },
                    {
                        title: "3. How to make it responsive on your website.",
                        url: "https://help.marketpushapps.com/en/articles/9349878-how-to-use-pricing-plan-templates#h_2e1bf35dc4",
                    },
                    {
                        title: "4. How do I change Vector arts/texts?",
                        url: "https://help.marketpushapps.com/en/articles/9349878-how-to-use-pricing-plan-templates#h_1d28060731",
                    },
                ],
            },
        ],
    },

    tutorial_videos: {
        show: false, // TBD
        videos: [{
            title: "How to use Pricing Plans",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png", // TBD
            url: "https://www.youtube.com/watch?v=123456", // TBD
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Pricing Plans",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};