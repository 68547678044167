

export default {
  app_info: {
    app_name: "mkp-dev-column-chart",
    app_id: "f52b2f22-7d26-4a01-b887-b512856eb163",
    app_title: "Column Chart",
    app_icon:
      "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/dev-mkp-charts-frontend/column-chart-frontend/logo/logo.jpg",
  },

  main_links: {
    faq_url:
      "https://help.marketpushapps.com/en/articles/9414706-how-to-use-column-chart",
    review_url:
      "https://www.wix.com/app-market/add-review/f52b2f22-7d26-4a01-b887-b512856eb163",
  },

  storage: {
    collection_name: "mkp-dev-column-chart",
  },

  tutorial_main: {
    show: true,
    title: "📘 Getting Started Guide",
    description:
      "The Column Chart Widget is a versatile tool that allows you to create and customize column charts for your website. You can easily configure the chart with different line types and stroke styles, feed data through various methods including API and manual input, and adjust the chart's design to match your site. Follow the detailed guide to get the most out of this powerful widget.",
    url: "https://help.marketpushapps.com/en/articles/9414706-how-to-use-column-chart",
  },

  tutorial_articles: {
    show: true,
    articles_collections: [
      {
        collection_name: "How to use Column Chart",
        articles: [
          {
            title: "1. How to Add Column Chart?",
            url: "https://help.marketpushapps.com/en/articles/9414706-how-to-use-column-chart#:~:text=1.%20How%20to%20Add%20Column%20Chart",
          },
          {
            title: "2. How To Represent data?",
            url: "https://help.marketpushapps.com/en/articles/9414706-how-to-use-column-chart#:~:text=2.%20How%20To%20Represent%20Your%20data.",
          },
          {
            title: "3. How To Change the Style of Your Chart?",
            url: "https://help.marketpushapps.com/en/articles/9414706-how-to-use-column-chart#:~:text=3.%20How%20To%20Change%20the%20Style%20of%20Your%20Chart.",
          },
          {
            title: "4. How do I get additional support?",
            url: "https://help.marketpushapps.com/en/articles/9414706-how-to-use-column-chart#:~:text=4.%20How%20do%20I%20get%20additional%20support%3F",
          },
          {
            title: "5. Add a Review for the app?",
            url: "https://help.marketpushapps.com/en/articles/9414706-how-to-use-column-chart#:~:text=5.%20Add%20a%20Review%20for%20the%20app",
          },
        ],
      },
      
    ],
  },

  tutorial_videos: {
    show: true,
    videos: [
      {
        title: "How to use Column Chart",
        thumbnail: "https://img.youtube.com/vi/8tqnBO2qR6Q/maxresdefault.jpg",
        url: "https://youtu.be/8tqnBO2qR6Q",
      },
    ],
  },

  intercom_widget: {
    show: true,
    appName: "Column Chart",

    negative_feedback_options: [
      {
        title: "Features do not work for me",
        message:
          "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
      },
      {
        title: "App setup is too complicated",
        message:
          "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
      },
      {
        title: "Subscriptions are too expensive",
        message:
          "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
      },
      {
        title: "App has issues",
        message:
          "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
      },
      {
        title: "Other",
        message: "🥲 Feedback: I have a problem with --> ",
      },
    ],
  },
};
