
export default {
    app_info: {
      app_name: "cart_recovery",
      app_id: "1c15809f-0715-427d-969d-3f0f3939418f",
      app_title: "Cart Recovery",

      app_icon:
        "https://static.wixstatic.com/media/19e924_89e6ef52bc1549e88748c2f3f95cc2bd~mv2.png",
    },
  
    main_links: {
      faq_url:
        "https://help.marketpushapps.com/en/articles/10483264-how-to-use-cart-recovery",
      review_url:
        "https://www.wix.com/app-market/add-review/1c15809f-0715-427d-969d-3f0f3939418f",
    },
  
    storage: {
      collection_name: "cart_recovery",
    },
  
    tutorial_main: {
      show: true,
      title: "📘 Getting Started Guide",
      description:
        "Cart Recovery helps recover lost sales by sending automated SMS, WhatsApp, or Facebook messages to customers who abandon their carts. A customizable popup encourages visitors to subscribe for reminders, which are sent at optimized times with direct payment links. Easily track recovered sales and monitor impact on revenue. Simple setup involves adding your phone number or linking a Facebook page. Boost conversions effortlessly by re-engaging potential buyers.",
      url: "https://help.marketpushapps.com/en/articles/10483264-how-to-use-cart-recovery",
    },
  
    tutorial_articles: {
      show: false,
      articles_collections: [
        {
          collection_name: "How to Use Line Chart",
          articles: [
            {
              title: "Step 1: Setting the Type of Line chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%201%3A%20Setting%20the%20Type%20of%20Line%20chart",
            },
            {
              title: "Step 2: Setting Lines count and Its data points",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%202%3A%20Setting%20Lines%20count%20and%20Its%20data%20points",
            },
            {
              title: "Step 3: Change the design of the chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%203%3A%20Change%20the%20design%20of%20the%20chart",
            },
            {
              title: "Add a Review for the app",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Add%20a%20Review%20for%20the%20app",
            },
          ],
        },
      ],
    },
  
    tutorial_videos: {
      show: true,
      videos: [
        {
          title: "Recover Abandoned Carts on Wix with WhatsApp Messages - How it works",
          thumbnail: "https://img.youtube.com/vi/77z5kscDD14/maxresdefault.jpg",
          url: "https://www.youtube.com/watch?v=77z5kscDD14",
        },
        {
            title: "Recover Abandoned Carts on Wix with Facebook Messages - How it works",
            thumbnail: "https://img.youtube.com/vi/1yhnt_hHoc8/maxresdefault.jpg",
            url: "https://www.youtube.com/watch?v=1yhnt_hHoc8",
          },
          {
            title: "Recover Abandoned Carts on Wix with SMS Messages - How it works",
            thumbnail: "https://img.youtube.com/vi/OXfKnRDVET4/maxresdefault.jpg",
            url: "https://www.youtube.com/watch?v=OXfKnRDVET4",
          },
      ],
    },
  
    intercom_widget: {
      show: true,
      appName: "Cart Recovery",
  
      negative_feedback_options: [
        {
          title: "Features do not work for me",
          message:
            "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
        {
          title: "App setup is too complicated",
          message:
            "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
        },
        {
          title: "Subscriptions are too expensive",
          message:
            "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
        },
        {
          title: "App has issues",
          message:
            "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
        },
        {
          title: "Other",
          message: "🥲 Feedback: I have a problem with --> ",
        },
      ],
    },
  };
 