
export default {
  app_info: {
    app_name: "horizontal-collapse-tabs",
    app_id: "1e4ac102-2a02-40c4-903c-024a762a002d",
    app_title: "Tabs - Horizontal Collapse",
    app_icon:
      "https://static.wixstatic.com/media/33caa7_8c8260b1e22842108991280fc5915bd6~mv2.png/v1/fill/w_108,h_108,al_c,q_85,usm_0.66_1.00_0.01/33caa7_8c8260b1e22842108991280fc5915bd6~mv2.webp",
  },

  main_links: {
    faq_url:
      "https://help.marketpushapps.com/en/articles/8895411-how-to-use-horizontal-collapse-tabs",
    review_url:
      "https://www.wix.com/app-market/add-review/1e4ac102-2a02-40c4-903c-024a762a002d",
  },

  tutorial_main: {
    show: true,
    title: "📘 Getting started guide",
    description:
      "The Tabs Horizontal Collapse app provides a sleek, pre-designed horizontal tab layout for enhancing website navigation. It allows users to easily configure the number of tabs, customize content, and match styles to their brand. Designed for seamless browsing, it works well for showcasing products, services, or information. Optimized for mobile devices, it ensures smooth navigation across all screens, offering an effortless setup and a modern, functional design for improved content display.",
    url: "https://help.marketpushapps.com/en/articles/8895411-how-to-use-horizontal-collapse-tabs",
  },

  tutorial_articles: {
    show: true,
    articles_collections: [
      {
        collection_name: "Getting Started",
        articles: [
          {
            title: "1. How to Add Horizontal Collapse Tabs",
            url: "https://help.marketpushapps.com/en/articles/8895411-how-to-use-horizontal-collapse-tabs#h_eedc097f3a",
          },
          {
            title: "2. How to duplicate an existing widget",
            url: "https://help.marketpushapps.com/en/articles/8895411-how-to-use-horizontal-collapse-tabs#h_bd272ef44f",
          },
          {
            title: "3. How to make it responsive",
            url: "https://help.marketpushapps.com/en/articles/8895411-how-to-use-horizontal-collapse-tabs#h_795b7018e9",
          },
          {
            title: "4. How change the number of tabs",
            url: "https://help.marketpushapps.com/en/articles/8895411-how-to-use-horizontal-collapse-tabs#h_c8aa60c592",
          },
          {
            title:
              "5. How to change the tab that shows when the page is loaded",
            url: "https://help.marketpushapps.com/en/articles/8895411-how-to-use-horizontal-collapse-tabs#h_dafc9601a0",
          },
          {
            title: "6. How to edit a tab content",
            url: "https://help.marketpushapps.com/en/articles/8895411-how-to-use-horizontal-collapse-tabs#h_38f3709922",
          },
          {
            title: "7. How to change the color of a Tab box",
            url: "https://help.marketpushapps.com/en/articles/8895411-how-to-use-horizontal-collapse-tabs#h_8b1b842ca3",
          },
        ],
      },
    ],
  },

  tutorial_videos: {
    show: true,
    videos: [
      {
        title: "How to add Tabs with Horizontal Collapse On Wix Site",
        thumbnail:
          "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/ask-for-review-assets/horizontal%20tabs_thumbnail.jpg",
        description:
          "This video from Market Push Apps shows you how to easily add horizontal collapsing tabs to your website using the app.",
        url: "https://youtu.be/3BcjCZ_vGPM?t=15",
      },
    ],
  },

  intercom_widget: {
    show: true,
    appName: "Tabs - Horizontal Collapse",

    negative_feedback_options: [
      {
        title: "Features do not work for me",
        message:
          "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
      },
      {
        title: "App setup is too complicated",
        message:
          "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
      },
      {
        title: "Subscriptions are too expensive",
        message:
          "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
      },
      {
        title: "App has issues",
        message:
          "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
      },
      {
        title: "Other",
        message: "🥲 Feedback: I have a problem with --> ",
      },
    ],
  },
};
