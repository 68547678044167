export default {
  app_info: {
    app_name: "donut_chart",
    app_id: "5bc3ff3c-e3b6-47a8-82f6-e53db71d3155", 
    app_title: "Donut Chart",
    app_icon:
      "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/dev-mkp-charts-frontend/donut-chart-frontend/Logo/Donut%20Chart%20App%20Logo.jpg",
  },

  main_links: {
    faq_url:
      "https://help.marketpushapps.com/en/articles/9300883-how-to-use-donut-chart",
    review_url:
      "https://www.wix.com/app-market/add-review/5bc3ff3c-e3b6-47a8-82f6-e53db71d3155", 
  },

  tutorial_main: {
    show: true,
    title: "📘 Getting started guide",
    description:
      "Donut Charta powerful tool for creating and customizing visually appealing donut charts. Offering four chart types, seamless integration, and dynamic data management, it ensures easy updates via a user-friendly panel, API, or Velo. With an intuitive interface and customizable styles, it helps site owners present complex data clearly while maintaining brand aesthetics.",
    url: "https://help.marketpushapps.com/en/articles/9300883-how-to-use-donut-chart",
  },

  tutorial_articles: {
    show: true,
    articles_collections: [
      {
        collection_name: "How to use Donut Chart",
        articles: [
          {
            title: "1. How to Add Donut Chart",
            url: "https://help.marketpushapps.com/en/articles/9300883-how-to-use-donut-chart#:~:text=1.%20How%20to%20Add%20Donut%20Chart",
          },
          {
            title: "2. How To Add Represent your data",
            url: "https://help.marketpushapps.com/en/articles/9300883-how-to-use-donut-chart#:~:text=2.%20How%20To%20Add%20Represent%20your%20data",
          },
          {
            title: "3. How To Change the Style of Your Chart.",
            url: "https://help.marketpushapps.com/en/articles/9300883-how-to-use-donut-chart#:~:text=3.%20How%20To%20Change%20the%20Style%20of%20Your%20Chart.",
          },
          {
            title: "4. How do I get additional support?",
            url: "https://help.marketpushapps.com/en/articles/9300883-how-to-use-donut-chart#:~:text=4.%20How%20do%20I%20get%20additional%20support%3F",
          },
          {
            title: "5. Add a Review for the app",
            url: "https://help.marketpushapps.com/en/articles/9300883-how-to-use-donut-chart#:~:text=5.%20Add%20a%20Review%20for%20the%20app",
          },
        ],
      },
    ],
  },

  tutorial_videos: {
    show: true, 
    videos: [
      {
        title: "How to use Donut Chart",
        thumbnail: "https://img.youtube.com/vi/uzeJiRUdHQc/maxresdefault.jpg", 
        url: "https://www.youtube.com/watch?v=uzeJiRUdHQc", 
      },
    ],
  },

  intercom_widget: {
    show: true,
    appName: "Donut Chart",

    negative_feedback_options: [
      {
        title: "Features do not work for me",
        message:
          "🥲 Feedback: Some features are not working for me, that is --> ",
      },
      {
        title: "User flow is more complex",
        message:
          "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
      },
      {
        title: "Subscriptions are too expensive",
        message:
          "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
      },
      {
        title: "Widgets have some issues",
        message:
          "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
      },
      {
        title: "Other",
        message: "🥲 Feedback: I have a problem with --> ",
      },
    ],
  },
};
