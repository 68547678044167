
export default {
    app_info: {
        app_name: "reviews_section",
        app_id: "7a0a529a-43bb-4b24-a2bc-a4b38c375e90", 
        app_title: "Reviews Section",
        
        app_icon: "https://static.wixstatic.com/media/19e924_16bfe4c40ff341d0bf6411f09d3eb3e4~mv2.jpg",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/9283365-how-to-use-reviews-section",
        review_url: "https://www.wix.com/app-market/add-review/7a0a529a-43bb-4b24-a2bc-a4b38c375e90", 
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "Your ultimate tool for crafting captivating review displays. With 12 dynamic templates at your disposal, customize your showcase to perfection. Hide or show elements within each template, tailoring them precisely to your needs. Experience the flexibility and creativity of customizable templates with Reviews Section.",
        url: "https://help.marketpushapps.com/en/articles/9283365-how-to-use-reviews-section",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
                collection_name: "Getting Started",
                articles: [
                    {
                        title: "1. How to add the app & change the template?",
                        url: "https://help.marketpushapps.com/en/articles/9283365-how-to-use-reviews-section#h_a863a25f52",
                    },
                    {
                        title: "2. What are the available templates?",
                        url: "https://help.marketpushapps.com/en/articles/9283365-how-to-use-reviews-section#h_6cd334d305",
                    },
                    {
                        title: "3. Can my customers add a review?",
                        url: "https://help.marketpushapps.com/en/articles/9283365-how-to-use-reviews-section#h_790db58c2a",
                    },
                    {
                        title: "4. How to modify the content?",
                        url: "https://help.marketpushapps.com/en/articles/9283365-how-to-use-reviews-section#h_74a006b1e6",
                    },
                    {
                        title: "4. How to add/or remove items",
                        url: "https://help.marketpushapps.com/en/articles/9283365-how-to-use-reviews-section#h_cd9e7a2422",
                    },
                ],
            },
        ],
    },

    tutorial_videos: {
        show: true, 
        videos: [{
            title: "How to use Reviews Section",
            thumbnail: "https://img.youtube.com/vi/ZATx2d9G9dQ/maxresdefault.jpg", 
            url: "https://www.youtube.com/watch?v=ZATx2d9G9dQ", 
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Reviews Section",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};