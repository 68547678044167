
export default {
  app_info: {
    app_name: "tabs-vertical-collapse",
    app_id: "92e2a2eb-7a04-4266-a421-cd41b7ed3497",
    app_title: "Tabs - Vertical Collapse",
    app_icon:
      "https://static.wixstatic.com/media/19e924_4b8b463dfe0843c287455078747fc24d~mv2.png/v1/fill/w_108,h_108,al_c,q_85,usm_0.66_1.00_0.01/19e924_4b8b463dfe0843c287455078747fc24d~mv2.webp",
  },

  main_links: {
    faq_url:
      "https://help.marketpushapps.com/en/articles/8980586-how-to-use-tabs-vertical-collapse",
    review_url:
      "https://www.wix.com/app-market/add-review/92e2a2eb-7a04-4266-a421-cd41b7ed3497",
  },

  tutorial_main: {
    show: true,
    title: "📘 Getting started guide",
    description:
      "The Tabs - Vertical Collapse** widget enhances website navigation with a sleek, mobile-optimized vertical tab layout. Easily configurable, it allows you to add or remove tabs and customize styles to align with your brand. Ideal for showcasing products or information, it offers a ready-to-use design with intuitive customization, ensuring a seamless user experience across devices. Transform your site with functional, stylish, and responsive navigation in just minutes.",
    url: "https://help.marketpushapps.com/en/articles/8980586-how-to-use-tabs-vertical-collapse",
  },

  tutorial_articles: {
    show: true,
    articles_collections: [
      {
        collection_name: "Getting Started",
        articles: [
          {
            title: "1. How to Add Horizontal Collapse Tabs",
            url: "https://help.marketpushapps.com/en/articles/8980586-how-to-use-tabs-vertical-collapse#h_4f9ac792bf",
          },
          {
            title: "2. How to duplicate an existing widget",
            url: "https://help.marketpushapps.com/en/articles/8980586-how-to-use-tabs-vertical-collapse#h_f1bdc3b10f",
          },
          {
            title: "3. How to make it responsive",
            url: "https://help.marketpushapps.com/en/articles/8980586-how-to-use-tabs-vertical-collapse#h_ff8b6caaf1",
          },
          {
            title: "4. How change the number of tabs",
            url: "https://help.marketpushapps.com/en/articles/8980586-how-to-use-tabs-vertical-collapse#h_d2f8c39b90",
          },
          {
            title:
              "5. How to change the tab that shows when the page is loaded",
            url: "https://help.marketpushapps.com/en/articles/8980586-how-to-use-tabs-vertical-collapse#h_755614015d",
          },
          {
            title: "6. How to edit a tab content",
            url: "https://help.marketpushapps.com/en/articles/8980586-how-to-use-tabs-vertical-collapse#h_c566c77238",
          },
          {
            title: "7. How to change the color of a Tab box",
            url: "https://help.marketpushapps.com/en/articles/8980586-how-to-use-tabs-vertical-collapse#h_e13820b08d",
          },
        ],
      },
    ],
  },

  tutorial_videos: {
    show: true,
    videos: [
      {
        title: "How to add Tabs Accordion on Wix",
        thumbnail:
          "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/ask-for-review-assets/verticle%20tabs_thumbnail.jpg",
        description:
          "This video from Market Push Apps shows you how to easily add vertical collapsing tabs to your website using the app.",
        url: "https://youtu.be/K6RxMUbnim8?t=17",
      },
    ],
  },

  intercom_widget: {
    show: true,
    appName: "Tabs - Vertical Collapse",

    negative_feedback_options: [
      {
        title: "Features do not work for me",
        message:
          "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
      },
      {
        title: "App setup is too complicated",
        message:
          "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
      },
      {
        title: "Subscriptions are too expensive",
        message:
          "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
      },
      {
        title: "App has issues",
        message:
          "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
      },
      {
        title: "Other",
        message: "🥲 Feedback: I have a problem with --> ",
      },
    ],
  },
};
