export default {
    app_info: {
        app_name: "payment_methods",
        app_id: "f4a75e26-f113-4bb9-898e-60fef2418182",
        app_title: "Payment Methods",
        app_icon: "https://static.wixstatic.com/media/40bdcd_35ecbb8445df414a887c3c3d8b1e9095~mv2.png",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/9993995-how-to-use-payment-methods",
        review_url: "https://www.wix.com/app-market/add-review/f4a75e26-f113-4bb9-898e-60fef2418182",
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "Display all supported payment methods on your site, helping customers choose their preferred option with ease and confidence.",
        url: "https://help.marketpushapps.com/en/articles/9993995-how-to-use-payment-methods",
    },

    tutorial_articles: {
        show: false,
        articles_collections: [{
                collection_name: "Getting Started",

                articles: [
                    {
                        title: "1. How to add the app & change the template?",
                        url: "https://help.marketpushapps.com/en/articles/9993995-how-to-use-payment-methods#h_a863a25f52",
                    },
                    {
                        title: "2. What are the available templates?",
                        url: "https://help.marketpushapps.com/en/articles/9993995-how-to-use-payment-methods#h_6cd334d305",
                    },
                    {
                        title: "3. Can my customers add a review?",
                        url: "https://help.marketpushapps.com/en/articles/9993995-how-to-use-payment-methods#h_790db58c2a",
                    },
                    {
                        title: "4. How to modify the content?",
                        url: "https://help.marketpushapps.com/en/articles/9993995-how-to-use-payment-methods#h_74a006b1e6",
                    },
                    {
                        title: "4. How to add/or remove items",
                        url: "https://help.marketpushapps.com/en/articles/9993995-how-to-use-payment-methods#h_cd9e7a2422",
                    },
                ],
            },
        ],
    },

    tutorial_videos: {
        show: false, 
        videos: [{
            title: "How to use Payment Methods",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png", 
            url: "https://www.youtube.com/watch?v=123456", 
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Payment Methods",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};