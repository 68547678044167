export default {
    app_info: {
        app_name: "request_quote",
        app_id: " 85846d22-96b6-4ae4-b6bf-397c21e3a3e8",
        app_title: "Request Quote",
        app_icon: "https://static.wixstatic.com/media/0904c3_2f34297126614565950cb3cbc6e3cb30~mv2.png",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/10295050-how-to-use-request-quote",
        review_url: "https://www.wix.com/app-market/add-review/ 85846d22-96b6-4ae4-b6bf-397c21e3a3e8",
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "The Request Quote allows users to request quotes for each product through a customizable form. Submitted quotes are managed via a simple dashboard, where users can view, mark them as resolved, and track them. The dashboard also enables the activation of quote requests for each product.",
        url: "https://help.marketpushapps.com/en/articles/10295050-how-to-use-request-quote",
    },

    tutorial_articles: {
        show: false,
        articles_collections: [{
            collection_name: "Getting Started",
            // TBD
            articles: [
                {
                    title: "1. How to add the app & change the template?",
                    url: "#h_a863a25f52",
                },
                {
                    title: "2. What are the available templates?",
                    url: "#h_6cd334d305",
                },
                {
                    title: "3. Can my customers add a review?",
                    url: "#h_790db58c2a",
                },
                {
                    title: "4. How to modify the content?",
                    url: "#h_74a006b1e6",
                },
                {
                    title: "4. How to add/or remove items",
                    url: "#h_cd9e7a2422",
                },
            ],
        },
        ],
    },

    tutorial_videos: {
        show: false, // TBD
        videos: [{
            title: "How to use Request Quote",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png", // TBD
            url: "https://www.youtube.com/watch?v=123456", // TBD
        },],
    },

    intercom_widget: {
        show: true,
        appName: "Request Quote",

        negative_feedback_options: [{
            title: "Features do not work for me",
            message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
        {
            title: "App setup is too complicated",
            message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
        },
        {
            title: "Subscriptions are too expensive",
            message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
        },
        {
            title: "App has issues",
            message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
        },
        {
            title: "Other",
            message: "🥲 Feedback: I have a problem with --> ",
        },
        ],
    },
};