

export default {
    app_info: {
        app_name: "loan_calculator",
        app_id: "a49f062d-3a18-4cad-8acd-52ea739993b8", 
        app_title: "Loan Calculator",
        app_icon: "https://static.wixstatic.com/media/19e924_bfe2d1d305c346eba5ed200e8932f8bc~mv2.jpg", 
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/9267606-how-to-use-loan-calculator", 
        review_url: "https://www.wix.com/app-market/add-review/a49f062d-3a18-4cad-8acd-52ea739993b8",
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "The Loan Calculator widget provides quick, accurate financial calculations tailored to your site’s needs. Set default values and ranges for loan amounts, interest rates, and repayment periods to enhance user experience. It offers insights on monthly payments, total interest, and repayment amounts, fostering informed decisions and trust. With Velo API integration for advanced customization, this tool boosts engagement by allowing users to explore financing scenarios seamlessly.",
        url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_b1bff97fc6",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
                collection_name: "Getting Started",
                articles: [
                    {
                        title: "1. How to add Loan Calculator to your site",
                        url: "https://help.marketpushapps.com/en/articles/9267606-how-to-use-loan-calculator#h_72ca0ee3e3",
                    },
                    {
                        title: "2. How to make the widget responsive",
                        url: "https://help.marketpushapps.com/en/articles/9267606-how-to-use-loan-calculator#h_a50bb66288",
                    },
                    {
                        title: "3. How to set the default values in the calculator",
                        url: "https://help.marketpushapps.com/en/articles/9267606-how-to-use-loan-calculator#h_103a904e3d",
                    },
                    {
                        title: "4. How to change the range for the calculator values",
                        url: "https://help.marketpushapps.com/en/articles/9267606-how-to-use-loan-calculator#h_149ea7df42",
                    },
                    {
                        title: "5. How is the interest calculated",
                        url: "https://help.marketpushapps.com/en/articles/9267606-how-to-use-loan-calculator#h_586187466d",
                    },



                ],
            },

        ],
    },

    tutorial_videos: {
        show: true,
        videos: [{
            title: "How to use Loan Calculator",
            thumbnail: "https://img.youtube.com/vi/OFbvEqZfPS0/maxresdefault.jpg",
            url: "https://www.youtube.com/watch?v=OFbvEqZfPS0",
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Loan Calculator",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};