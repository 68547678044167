
export default {
    app_info: {
        app_name: "team_members",
        app_id: "b86b7803-80f0-450f-8bd0-3dbd17a5ef6e",
        app_title: "Team Members",
        app_icon: "https://static.wixstatic.com/media/19e924_93861790942e4189856761049928822b~mv2.png/v1/fill/w_108,h_108,al_c,q_85,usm_0.66_1.00_0.01/19e924_93861790942e4189856761049928822b~mv2.webp",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/9211254-how-to-use-team-members",
        review_url: "https://www.wix.com/app-market/add-review/b86b7803-80f0-450f-8bd0-3dbd17a5ef6e",
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "The Team Members app makes showcasing your team effortless and stylish. Choose from 9 customizable templates to create a visually appealing team section that aligns with your site’s design. Easily configure team profiles by adding photos, roles, descriptions, and social links using a user-friendly interface—no coding required. Tailor every detail to match your brand, ensuring seamless site integration. Highlight your team’s strengths and connect with your audience with ease.",
        url: "https://help.marketpushapps.com/en/articles/9211254-how-to-use-team-members",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
                collection_name: "Getting Started",
                articles: [
                    {
                        title: "1. How can the Team Members app help you?",
                        url: "https://help.marketpushapps.com/en/articles/9211254-how-to-use-team-members#h_cdb6cb4dc5",
                    },
                    {
                        title: "2. How to Add or Remove Items?",
                        url: "https://help.marketpushapps.com/en/articles/9211254-how-to-use-team-members#h_c36a6788ad",
                    },
                    {
                        title: "3. How do I change colors?",
                        url: "https://help.marketpushapps.com/en/articles/9211254-how-to-use-team-members#h_a94649ee1e",
                    },

                ],
            },
        ],
    },

    tutorial_videos: {
        show: true,
        videos: [{
            title: "How to use Team Members",
            thumbnail: "https://img.youtube.com/vi/tPqOPU8thgQ/maxresdefault.jpg",
            url: "https://www.youtube.com/watch?v=tPqOPU8thgQ",
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Team Members",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};