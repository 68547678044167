

export default {
    app_info: {
        app_name: "pet_adoption",
        app_id: "83c01a60-d69b-4445-b68b-1c52829ece3d",
        app_title: "Pet Adoption",
        app_icon: "https://static.wixstatic.com/media/19e924_cd5192c631c247f1bd844498d0c1dff6~mv2.png", 
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/9242572-how-to-use-pet-adoption",
        review_url: "https://www.wix.com/app-market/add-review/83c01a60-d69b-4445-b68b-1c52829ece3d",
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "The 'Pet Adoption' app streamlines showcasing pets for adoption centers and pet shops. It features five customizable templates to match any site style, enhancing the visual appeal of listings. Adding and updating pet profiles is quick and easy, allowing for rich visuals and detailed descriptions, including health info and unique traits. Versatile and user-friendly, this app helps connect pets with loving homes while boosting visibility and engagement.",
        url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_b1bff97fc6",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
                collection_name: "Getting Started",
                articles: [
                    {
                        title: "1. How can the Pet Adoption app help you?",
                        url: "https://help.marketpushapps.com/en/articles/9242572-how-to-use-pet-adoption#h_12d64a4b2c",
                    },
                    {
                        title: "2. How to Add or Remove Items?",
                        url: "https://help.marketpushapps.com/en/articles/9242572-how-to-use-pet-adoption#h_4a6cd528fa",
                    },
                    {
                        title: "3. How do I change colors?",
                        url: "https://help.marketpushapps.com/en/articles/9242572-how-to-use-pet-adoption#h_0358041bd8",
                    },

                ],
            },
        ],
    },

    tutorial_videos: {
        show: true,
        videos: [{
            title: "How to use Pet Adoption",
            thumbnail: "https://img.youtube.com/vi/yMO0T_hbL1Y/maxresdefault.jpg",
            url: "https://www.youtube.com/watch?v=yMO0T_hbL1Y",
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Pet Adoption",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};