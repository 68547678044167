
export default {
    app_info: {
      app_name: "google_shopping_by_mkp",
      app_id: "a2916d89-ac24-4ba0-a2a6-444ace0d3f9f",
      app_title: "Google Shopping by MKP",

      app_icon:
        "https://static.wixstatic.com/media/33caa7_27b38c45a58c4fdca215acf092b44f13~mv2.png",
    },
  
    main_links: {
      faq_url:
        "https://help.marketpushapps.com/en/articles/8713913-how-to-use-google-shopping-by-mkp-app",
      review_url:
        "https://www.wix.com/app-market/add-review/a2916d89-ac24-4ba0-a2a6-444ace0d3f9f",
    },
  
    storage: {
      collection_name: "google_shopping_by_mkp",
    },
  
    tutorial_main: {
      show: true,
      title: "📘 Getting Started Guide",
      description:
        "Google Shopping by MKP helps you get your products visible under the Shopping tab in Google Search. The app automatically syncs your store's products with Google Merchant Center, keeping everything up to date without manual effort. It generates a CSV feed linked to your Google Merchant Account, making it easy to publish products, including variants and complex listings (e.g., apparel). The app supports unlimited product changes, out-of-stock management, and provides tutorials for setting up your Merchant Account, ensuring a smooth integration process.",
      url: "https://help.marketpushapps.com/en/articles/8713913-how-to-use-google-shopping-by-mkp-app",
    },
  
    tutorial_articles: {
      show: false,
      articles_collections: [
        {
          collection_name: "How to Use Line Chart",
          articles: [
            {
              title: "Step 1: Setting the Type of Line chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%201%3A%20Setting%20the%20Type%20of%20Line%20chart",
            },
            {
              title: "Step 2: Setting Lines count and Its data points",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%202%3A%20Setting%20Lines%20count%20and%20Its%20data%20points",
            },
            {
              title: "Step 3: Change the design of the chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%203%3A%20Change%20the%20design%20of%20the%20chart",
            },
            {
              title: "Add a Review for the app",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Add%20a%20Review%20for%20the%20app",
            },
          ],
        },
      ],
    },
  
    tutorial_videos: {
      show: true,
      videos: [
        {
          title: "How to use Google Shopping by MKP",
          thumbnail: "https://img.youtube.com/vi/lOUj8tw4c5w/maxresdefault.jpg",
          url: "https://www.youtube.com/watch?v=lOUj8tw4c5w",
        },
      ],
    },
  
    intercom_widget: {
      show: true,
      appName: "Google Shopping by MKP",
  
      negative_feedback_options: [
        {
          title: "Features do not work for me",
          message:
            "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
        {
          title: "App setup is too complicated",
          message:
            "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
        },
        {
          title: "Subscriptions are too expensive",
          message:
            "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
        },
        {
          title: "App has issues",
          message:
            "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
        },
        {
          title: "Other",
          message: "🥲 Feedback: I have a problem with --> ",
        },
      ],
    },
  };
  