export default {
  app_info: {
    app_name: "trust-badges",
    app_id: "9f0cf250-6b66-4ac5-8d92-5a36a8d3f5ee",
    app_title: "Trust Badges",
    app_icon:
      "https://static.wixstatic.com/media/19e924_f9aad191f5254881a8d3107c6bb2ccb3~mv2.png",
  },

  main_links: {
    faq_url:
      "https://help.marketpushapps.com/en/articles/9944405-how-to-use-trust-badges",

    review_url:
      "https://www.wix.com/app-market/add-review/9f0cf250-6b66-4ac5-8d92-5a36a8d3f5ee",
  },

  storage: {
    collection_name: "trust-badges",
  },

  tutorial_main: {
    show: true,
    title: "📘 Getting Started Guide",
    description:
      "The Trust Badges Widget enhances your website's credibility by displaying customizable trust badges. Choose from multiple templates, adjust colors, sizes, and styles, and manage subtitle visibility. With quick loading presets and seamless mobile compatibility, this widget integrates smoothly into your site. Follow the guide to maximize its potential",
    url: "https://help.marketpushapps.com/en/articles/9944405-how-to-use-trust-badges",
  },

  tutorial_articles: {
    show: false,
    articles_collections: [
      {
        collection_name: "How to use Trust Badges",
        articles: [
          {
            title: "1. How to add Area Chart?",
            url: "https://help.marketpushapps.com/en/articles/9414693-how-to-use-area-chart#:~:text=1.%20How%20to%20Add%20Area%20Chart",
          },
          {
            title: "2. How To Represent Your data in Area Chart?",
            url: "https://help.marketpushapps.com/en/articles/9414693-how-to-use-area-chart#:~:text=2.%20How%20To%20Represent%20Your%20data.",
          },
          {
            title: "3. How To Change the Style of Your Chart?",
            url: "https://help.marketpushapps.com/en/articles/9414693-how-to-use-area-chart#:~:text=3.%20How%20To%20Change%20the%20Style%20of%20Your%20Chart.",
          },
        ],
      },
    ],
  },

  tutorial_videos: {
    show: true,
    videos: [
      {
        title: "How to use Trust Badges",
        thumbnail: "https://img.youtube.com/vi/2yjhjb_TElA/maxresdefault.jpg",
        url: "https://www.youtube.com/watch?v=2yjhjb_TElA",
      },
    ],
  },

  intercom_widget: {
    show: true,
    appName: "Trust Badges",

    negative_feedback_options: [
      {
        title: "Features do not work for me",
        message:
          "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
      },
      {
        title: "App setup is too complicated",
        message:
          "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
      },
      {
        title: "Subscriptions are too expensive",
        message:
          "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
      },
      {
        title: "App has issues",
        message:
          "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
      },
      {
        title: "Other",
        message: "🥲 Feedback: I have a problem with --> ",
      },
    ],
  },
};
