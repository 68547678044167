export default {
    app_info: {
        app_name: "whatapp_notifications",
        app_id: "d6f28d08-51dd-4745-ad80-21a2db4ed33d",
        app_title: "WhatsApp Notifications by MKP",
        app_icon: "https://static.wixstatic.com/media/33caa7_13c1f3e1903f45c78e1f88b6a0b82aa6~mv2.png",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/8713895-how-to-use-vacation-mode", 
        review_url: "https://www.wix.com/app-market/add-review/d6f28d08-51dd-4745-ad80-21a2db4ed33d",
    },
    storage: {
        collection_name: "whatapp_notifications",
      },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "WhatsApp Notifications by MKP helps you stay informed and keep customers updated through WhatsApp messages for key website events. As a site owner, receive alerts for new store orders, booking changes, restaurant orders, event ticket purchases, and form submissions. For customers, automate WhatsApp messages for booking reminders, cancellations, and restaurant order updates. The app integrates directly with your website's stores, bookings, events, and restaurants, providing message logs and analytics. Keep communication seamless and real-time with this powerful automation tool available globally in English.",
        url: "https://help.marketpushapps.com/en/articles/8713895-how-to-use-vacation-mode",
    },
    

    tutorial_articles: {
        show: true,
        articles_collections: [{
            collection_name: "Help Articles",
            articles: [
                {
                    title: "The Popup is not showing on homepage",
                    url: "https://help.marketpushapps.com/en/articles/8863548-the-popup-is-not-showing-on-homepage",
                },
            ],
        },
        ],
    },

    tutorial_videos: {
        show: false,
        videos: [{
            title: "How to use Social Media Share & Follow",
            thumbnail: "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/ask-for-review-assets/social%20media_yt_thumbnail.png", 
            url: "https://www.youtube.com/watch?v=8xbL8oERHBc",
        },],
    },

    intercom_widget: {
        show: true,
        appName: "WhatsApp Notifications by MKP",

        negative_feedback_options: [{
            title: "Features do not work for me",
            message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};