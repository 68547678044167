

export default {
    app_info: {
        app_name: "vimeo-gallery",
        app_id: "57a284b9-a8bb-47b2-9431-a8e93de11d89",
        app_title: "Vimeo Gallery",
        app_icon: "https://static.wixstatic.com/media/19e924_18169424bd9c44b5bee1ab87d16d4da5~mv2.jpg/v1/fill/w_108,h_108,al_c,q_80,usm_0.66_1.00_0.01/19e924_18169424bd9c44b5bee1ab87d16d4da5~mv2.webp", // TBC
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/8904748-how-to-use-vimeo-gallery",
        review_url: "https://www.wix.com/app-market/add-review/57a284b9-a8bb-47b2-9431-a8e93de11d89",
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "The Vimeo Gallery app enhances your website with a dynamic video showcase. Easily embed videos via IDs or links and customize the gallery with three video size options to match your site's design. Built-in pagination ensures seamless navigation, encouraging visitor engagement. With automatic updates, your gallery stays fresh effortlessly, offering an intuitive, visually appealing way to display Vimeo content and elevate your site’s multimedia experience.",
        url: "https://help.marketpushapps.com/en/articles/8904748-how-to-use-vimeo-gallery",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
                collection_name: "Getting Started",
                articles: [
                    {
                        title: "1. How to add Vimeo Gallery to your site",
                        url: "https://help.marketpushapps.com/en/articles/8904748-how-to-use-vimeo-gallery#h_d998c2834b",
                    },
                    {
                        title: "2. How to connect Vimeo videos to your Gallery",
                        url: "https://help.marketpushapps.com/en/articles/8904748-how-to-use-vimeo-gallery#h_3a1654a75c",
                    },
                    {
                        title: "3. How can I make videos smaller/bigger",
                        url: "https://help.marketpushapps.com/en/articles/8904748-how-to-use-vimeo-gallery#h_ac45d10034",
                    },
                    {
                        title: "4. How to make the widget responsive on Mobile and Desktop",
                        url: "https://help.marketpushapps.com/en/articles/8904748-how-to-use-vimeo-gallery#h_0ea1fc0de7",
                    },
                    {
                        title: "5. My Mobile version style modifications are not applied",
                        url: "https://help.marketpushapps.com/en/articles/8904748-how-to-use-vimeo-gallery#h_6d1c53ea68",
                    },
                    {
                        title: "6. How do I modify the background color",
                        url: "https://help.marketpushapps.com/en/articles/8904748-how-to-use-vimeo-gallery#h_c6cd4cd34b",
                    },
                    {
                        title: "7. How can I add Pagination",
                        url: "https://help.marketpushapps.com/en/articles/8904748-how-to-use-vimeo-gallery#h_3a5545ee3b",
                    },

                ],
            },

        ],
    },

    tutorial_videos: {
        show: false,
        videos: [{
            title: "How to use Vimeo Gallery",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
            url: "https://www.youtube.com/watch?v=123456",
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Vimeo Gallery",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, that is --> ",
            },
            {
                title: "User flow is more complex",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "Widgets have some issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};