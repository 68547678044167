

export default {
    app_info: {
        app_name: "most_sold_products",
        app_id: "ffa8edb9-f262-40af-841d-3cdd2df79bb3",
        app_title: "Most Sold Products",
        app_icon: "https://static.wixstatic.com/media/a6d9b6_b5394344ed11414192821b2540c937a8~mv2.png", 
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/10800316-how-to-use-most-sold-products",
        review_url: "https://www.wix.com/app-market/add-review/ffa8edb9-f262-40af-841d-3cdd2df79bb3",
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "Most Sold Products is a powerful Wix Blocks app that helps store owners track and display their top-selling products effortlessly. This app analyses order data, identifies the best-performing products, and ranks them based on sales. With customisable design options and seamless integration with your Wix store, you can showcase your best-selling items to boost customer engagement and sales. Optimise your store strategy with real-time insights on your most popular products.",
        url: "https://help.marketpushapps.com/en/articles/10800316-how-to-use-most-sold-products",
    },

    tutorial_articles: {
        show: false,
        articles_collections: [{
                collection_name: "Getting Started",
                articles: [
                    {
                        title: "1. How to change the icon template",
                        url: "https://help.marketpushapps.com/en/articles/10800316-how-to-use-most-sold-products#h_f6d2392b32",
                    },
                    {
                        title: "2. How to remove the watermark branding",
                        url: "https://help.marketpushapps.com/en/articles/10800316-how-to-use-most-sold-products#h_a4dd867779",
                    },
                    {
                        title: "3. How to make the widget responsive on Wix Editor (default)",
                        url: "https://help.marketpushapps.com/en/articles/10800316-how-to-use-most-sold-products#h_1a9cafd80a",
                    },
                    {
                        title: "4. How to make the widget responsive on Wix Studio / EditorX.",
                        url: "https://help.marketpushapps.com/en/articles/10800316-how-to-use-most-sold-products#h_275c81b619",
                    },
                    {
                        title: "5. How to customise the colors of default icons",
                        url: "https://help.marketpushapps.com/en/articles/10800316-how-to-use-most-sold-products#h_8057d1609d",
                    },
                    {
                        title: "6. How to replace the icons in the app with your own",
                        url: "https://help.marketpushapps.com/en/articles/10800316-how-to-use-most-sold-products#h_b3dae3b500",
                    },
                ],
            },

        ],
    },

    tutorial_videos: {
        show: false,
        videos: [{
            title: "How to use Most Sold Products",
            thumbnail: "https://img.youtube.com/vi/G8KChro2mds/maxresdefault.jpg",
            url: "https://www.youtube.com/watch?v=G8KChro2mds",
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Most Sold Products",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};