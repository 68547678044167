
export default {
    app_info: {
        app_name: "wedding-countdown-timer",
        app_id: "c462df7b-4c7e-4692-bbb9-e97bde415150",
        app_title: "Wedding Countdown Timer",
        app_icon: "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/dev-mkp-ce-wedding-countdown-timer/wedding-countdown-logo.png",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/9053700-how-to-use-weddings-countdown-timer",
        review_url: "https://www.wix.com/app-market/add-review/c462df7b-4c7e-4692-bbb9-e97bde415150",
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "The Wedding Countdown Timer app enhances your wedding website with a stylish, customizable countdown. Choose from 8 elegant templates and personalize every detail to match your theme. Its dual-state functionality transitions from a live countdown to a heartfelt post-event message, capturing every phase of your celebration. Easy to set up, it engages guests by building anticipation and creating a shared experience. Perfect for adding joy and elegance to your special day.",
        url: "https://help.marketpushapps.com/en/articles/9053700-how-to-use-wedding-countdown-timer",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
                collection_name: "Getting Started",
                articles: [
                    {
                        title: "1. How to add Wedding Countdown Timer",
                        url: "https://help.marketpushapps.com/en/articles/9053700-how-to-use-wedding-countdown-timer#h_0a8abe050d",
                    },
                    {
                        title: "2. How to change the Template",
                        url: "https://help.marketpushapps.com/en/articles/9053700-how-to-use-wedding-countdown-timer#h_b0a63936bd",
                    },
                    {
                        title: "3. How to change an Image",
                        url: "https://help.marketpushapps.com/en/articles/9053700-how-to-use-wedding-countdown-timer#h_103b5c5cf1",
                    },
                    {
                        title: "4. How to change Color & Style",
                        url: "https://help.marketpushapps.com/en/articles/9053700-how-to-use-wedding-countdown-timer#h_8d2196f3b6",
                    },
                    {
                        title: "5. How to change the counter date",
                        url: "https://help.marketpushapps.com/en/articles/9053700-how-to-use-wedding-countdown-timer#h_2d1c0a8fb8",
                    },
                    {
                        title: "6. What happens at end of the counter when it reaches 00:00:00:00 and how to customise it",
                        url: "https://help.marketpushapps.com/en/articles/9053700-how-to-use-wedding-countdown-timer#h_350c5db8f7",
                    },
                ],
            },

        ],
    },

    tutorial_videos: {
        show: true,
        videos: [{
            title: "How to use Wedding Countdown Timer",
            thumbnail: "https://img.youtube.com/vi/H-H9tdXl7DM/maxresdefault.jpg",
            url: "https://www.youtube.com/watch?v=H-H9tdXl7DM",
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Wedding Countdown Timer",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};