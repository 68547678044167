
export default {
    app_info: {
        app_name: "events-countdown-timer",
        app_id: "eddb4acf-10ac-4bc1-aaae-628c3388726e",
        app_title: "Events Countdown Timer",
        app_icon: "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/dev-mkp-ce-events-countdown-timer/logo.jpg",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/9053726-how-to-use-event-countdown-timer",
        review_url: "https://www.wix.com/app-market/add-review/eddb4acf-10ac-4bc1-aaae-628c3388726e",
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "The 'Event Countdown Timer' app enhances your event promotion with 11 customizable templates tailored to any occasion, from seminars to concerts. Personalize images, styles, and colors to match your brand seamlessly. Its dual-state functionality keeps engagement high, transitioning from a live countdown to celebratory messages or calls to action. Easy to set up and embed, this tool simplifies preparation, boosts site engagement, and builds excitement for your event.",
        url: "https://help.marketpushapps.com/en/articles/9053726-how-to-use-event-countdown-timer",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
                collection_name: "Getting Started",
                articles: [
                    {
                        title: "1. How to add Event Countdown Timer",
                        url: "https://help.marketpushapps.com/en/articles/9053726-how-to-use-event-countdown-timer#h_e7409addff",
                    },
                    {
                        title: "2. How to change the Template",
                        url: "https://help.marketpushapps.com/en/articles/9053726-how-to-use-event-countdown-timer#h_320ab05554",
                    },
                    {
                        title: "3. How to change an Image",
                        url: "https://help.marketpushapps.com/en/articles/9053726-how-to-use-event-countdown-timer#h_21dfc0d53d",
                    },
                    {
                        title: "4. How to change Color & Style",
                        url: "https://help.marketpushapps.com/en/articles/9053726-how-to-use-event-countdown-timer#h_8e53039f6e",
                    },
                    {
                        title: "5. How to change the counter date",
                        url: "https://help.marketpushapps.com/en/articles/9053726-how-to-use-event-countdown-timer#h_f2b4e8269c",
                    },
                    {
                        title: "6. What happens at end of the counter when it reaches 00:00:00:00 and how to customise it",
                        url: "https://help.marketpushapps.com/en/articles/9053726-how-to-use-event-countdown-timer#h_ae69f8a588",
                    },

                ],
            },
        ],
    },

    tutorial_videos: {
        show: true,
        videos: [{
            title: "How to use Events Countdown Timer",
            thumbnail: "https://img.youtube.com/vi/MYHvwyU_yHY/maxresdefault.jpg",
            url: "https://www.youtube.com/watch?v=MYHvwyU_yHY",
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Events Countdown Timer",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};