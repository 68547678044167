

export default {
  app_info: {
    app_name: "mkp-dev-bar-chart",
    app_id: "6dc69cb9-583a-447b-a3fa-fb825f76bf06",
    app_title: "Bar Chart",
    app_icon:
      "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/dev-mkp-charts-frontend/bar-chart-frontend/logo/logo.jpg",
  },

  main_links: {
    faq_url:
      "https://help.marketpushapps.com/en/articles/9414706-how-to-use-column-chart",
    review_url:
      "https://www.wix.com/app-market/add-review/6dc69cb9-583a-447b-a3fa-fb825f76bf06",
  },

  storage: {
    collection_name: "mkp-dev-bar-chart",
  },

  tutorial_main: {
    show: true,
    title: "📘 Getting Started Guide",
    description:
      "The Bar Chart Widget is a versatile tool that allows you to create and customize bar charts for your website. You can easily configure the chart with different line types and stroke styles, feed data through various methods including API and manual input, and adjust the chart's design to match your site. Follow the detailed guide to get the most out of this powerful widget.",
    url: "https://help.marketpushapps.com/en/articles/9414706-how-to-use-column-chart",
  },

  tutorial_articles: {
    show: false,
    articles_collections: [
      {
        collection_name: "Getting Started",
        articles: [
          {
            title: "1. How to Add Column Chart",
            url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_e750f2ba12",
          },
          {
            title: "2. How To Represent Your data.",
            url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_3eb4f36ee2",
          },
          {
            title: "3. How To Change the Style of Your Chart.",
            url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_9e7bc726e1",
          },
          {
            title:
              "4. How do I get additional support?",
            url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_5e7e7e3036",
          },
          // {
          //   title:
          //     "5. Add a Review for the app",
          //   url: "https://help.marketpushapps.com/en/articles/8900488-how-to-use-image-hotspots#h_5e7e7e3036",
          // },
        ],
      },
    ],
  },

  tutorial_videos: {
    show: true,
    videos: [
      {
        title: "How to use Bar Chart",
        thumbnail: "https://img.youtube.com/vi/bByYVv4sX8g/maxresdefault.jpg",
        url: "https://www.youtube.com/watch?v=bByYVv4sX8g",
      },
    ],
  },

  intercom_widget: {
    show: true,
    appName: "Bar Chart",

    negative_feedback_options: [
      {
        title: "Features do not work for me",
        message:
          "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
      },
      {
        title: "App setup is too complicated",
        message:
          "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
      },
      {
        title: "Subscriptions are too expensive",
        message:
          "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
      },
      {
        title: "App has issues",
        message:
          "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
      },
      {
        title: "Other",
        message: "🥲 Feedback: I have a problem with --> ",
      },
    ],
  },
};
