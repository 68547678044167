
export default {
    app_info: {
      app_name: "download_product_as_pdf",
      app_id: "dafdae60-1886-4bcb-8a96-3a9b7571d36c",
      app_title: "Download Product as Pdf",
      app_icon:
        "https://static.wixstatic.com/media/40bdcd_51883338fe8a414f896978d92924b32e~mv2.jpg",
    },
  
    main_links: {
      faq_url:
        "https://help.marketpushapps.com/en/articles/10748885-how-to-use-download-product-as-pdf",
      review_url:
        "https://www.wix.com/app-market/add-review/dafdae60-1886-4bcb-8a96-3a9b7571d36c",
    },
  
    storage: {
      collection_name: "download_product_as_pdf",
    },
  
    tutorial_main: {
      show: true,
      title: "📘 Getting Started Guide",
      description:
        "Download Product as PDF enhances product pages by allowing users to download product details as a customizable PDF. It features a configurable download button, two editable PDF templates, and branding options like logo uploads. Users can preview PDFs, track downloads with analytics, and customize the loading state. Improve customer experience with structured, downloadable product information. Install now!",
      url: "https://help.marketpushapps.com/en/articles/10748885-how-to-use-download-product-as-pdf",
    },
  
    tutorial_articles: {
      show: false,
      articles_collections: [
        {
          collection_name: "How to Use Line Chart",
          articles: [
            {
              title: "Step 1: Setting the Type of Line chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%201%3A%20Setting%20the%20Type%20of%20Line%20chart",
            },
            {
              title: "Step 2: Setting Lines count and Its data points",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%202%3A%20Setting%20Lines%20count%20and%20Its%20data%20points",
            },
            {
              title: "Step 3: Change the design of the chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%203%3A%20Change%20the%20design%20of%20the%20chart",
            },
            {
              title: "Add a Review for the app",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Add%20a%20Review%20for%20the%20app",
            },
          ],
        },
      ],
    },
  
    tutorial_videos: {
      show: false,
      videos: [
        {
          title: "How to use image hotspots",
          thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
          url: "https://www.youtube.com/watch?v=123456",
        },
      ],
    },
  
    intercom_widget: {
      show: true,
      appName: "Download Product as Pdf",
  
      negative_feedback_options: [
        {
          title: "Features do not work for me",
          message:
            "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
        {
          title: "App setup is too complicated",
          message:
            "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
        },
        {
          title: "Subscriptions are too expensive",
          message:
            "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
        },
        {
          title: "App has issues",
          message:
            "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
        },
        {
          title: "Other",
          message: "🥲 Feedback: I have a problem with --> ",
        },
      ],
    },
  };
  