
export default {
    app_info: {
      app_name: "calendly_embeded",
      app_id: "c560c7eb-2495-45a0-a714-d3ae13ef9398",
      app_title: "Calendly",
      app_icon:
        "https://static.wixstatic.com/media/0904c3_c96307575c02454594d430fcb3c97be5~mv2.png",
    },
  
    main_links: {
      faq_url:
        "https://help.marketpushapps.com/en/articles/10472903-how-to-use-calendly-embed",
      review_url:
        "https://www.wix.com/app-market/add-review/c560c7eb-2495-45a0-a714-d3ae13ef9398",
    },
  
    storage: {
      collection_name: "calendly_embeded",
    },
  
    tutorial_main: {
      show: true,
      title: "📘 Getting Started Guide",
      description:
        "Embed and customize your Calendly booking experience with options like inline, popup widget, or popup text. Personalize colors, text, and buttons to match your brand while hiding event details or GDPR banners for a cleaner look. Enjoy real-time design previews for seamless integration. Whether an embedded form or interactive popup, this app simplifies scheduling and enhances visitor engagement effortlessly.",
      url: "https://help.marketpushapps.com/en/articles/10472903-how-to-use-calendly-embed",
    },
  
    tutorial_articles: {
      show: false,
      articles_collections: [
        {
          collection_name: "How to Use Line Chart",
          articles: [
            {
              title: "Step 1: Setting the Type of Line chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%201%3A%20Setting%20the%20Type%20of%20Line%20chart",
            },
            {
              title: "Step 2: Setting Lines count and Its data points",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%202%3A%20Setting%20Lines%20count%20and%20Its%20data%20points",
            },
            {
              title: "Step 3: Change the design of the chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%203%3A%20Change%20the%20design%20of%20the%20chart",
            },
            {
              title: "Add a Review for the app",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Add%20a%20Review%20for%20the%20app",
            },
          ],
        },
      ],
    },
  
    tutorial_videos: {
      show: false,
      videos: [
        {
          title: "How to use image hotspots",
          thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
          url: "https://www.youtube.com/watch?v=123456",
        },
      ],
    },
  
    intercom_widget: {
      show: true,
      appName: "Calendly",
  
      negative_feedback_options: [
        {
          title: "Features do not work for me",
          message:
            "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
        {
          title: "App setup is too complicated",
          message:
            "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
        },
        {
          title: "Subscriptions are too expensive",
          message:
            "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
        },
        {
          title: "App has issues",
          message:
            "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
        },
        {
          title: "Other",
          message: "🥲 Feedback: I have a problem with --> ",
        },
      ],
    },
  };
  