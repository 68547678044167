
export default {
    app_info: {
      app_name: "google_sheets_table",
      app_id: "920347d2-9f58-4ec6-bf2d-ac49fd76777b",
      app_title: "Google Sheets Table",

      app_icon:
        "https://static.wixstatic.com/media/c7fe21_538532ca23504bc9a21e6fd40833ba05~mv2.png",
    },
  
    main_links: {
      faq_url:
        "https://help.marketpushapps.com/en/collections/3854935-google-sheets-table",
      review_url:
        "https://www.wix.com/app-market/add-review/920347d2-9f58-4ec6-bf2d-ac49fd76777b",
    },
  
    storage: {
      collection_name: "google_sheets_table",
    },
  
    tutorial_main: {
      show: true,
      title: "📘 Getting Started Guide",
      description:
        "The Google Sheets Table app allows users to seamlessly display a live-updating spreadsheet on their site with minimal effort. Changes made in Google Sheets automatically reflect in the site table. Choose from various display templates and customize styling, layouts, and colors to match your design. Enable search functionality for easy navigation and access search analytics to refine user experience. This app simplifies data sharing and enhances site interactivity.",
      url: "https://help.marketpushapps.com/en/collections/3854935-google-sheets-table",
    },
  
    tutorial_articles: {
      show: true,
      articles_collections: [
        {
          collection_name: "How to Use Google Sheets Table",
          articles: [
            {
              title: "How to add a Google Sheets Table?",
              url: "https://help.marketpushapps.com/en/articles/8696444-how-to-add-a-google-sheets-table",
            },
            {
              title: "I cannot add a new spreadsheet",
              url: "https://help.marketpushapps.com/en/articles/6985806-i-cannot-add-a-new-spreadsheet",
            },
            {
              title: "My table has errors / How to add my data",
              url: "https://help.marketpushapps.com/en/articles/6987992-my-table-has-errors-how-to-add-my-data",
            },
            {
              title: "How to add links",
              url: "https://help.marketpushapps.com/en/articles/6984435-how-to-add-links",
            },
            {
                title: "My changes are not saving / reverting",
                url: "https://help.marketpushapps.com/en/articles/6985888-my-changes-are-not-saving-reverting",
              },
              {
                title: "Missing Columns - How to add new columns",
                url: "https://help.marketpushapps.com/en/articles/8174212-missing-columns-how-to-add-new-columns",
              },
              {
                title: "The table does not show all rows",
                url: "https://help.marketpushapps.com/en/articles/6985795-the-table-does-not-show-all-rows",
              },
              {
                title: "Can I add merged cells in the sheet?",
                url: "https://help.marketpushapps.com/en/articles/6988196-can-i-add-merged-cells-in-the-sheet",
              },
              {
                title: "How to increase the width of a column",
                url: "https://help.marketpushapps.com/en/articles/6985894-how-to-increase-the-width-of-a-column",
              },
              {
                title: "How to add images",
                url: "https://help.marketpushapps.com/en/articles/6984863-how-to-add-images",
              },
              {
                title: "How to display a single cell value instead of a table",
                url: "https://help.marketpushapps.com/en/articles/6985866-how-to-display-a-single-cell-value-instead-of-a-table",
              },
              {
                title: "How to add Bold text",
                url: "https://help.marketpushapps.com/en/articles/7939253-how-to-add-bold-text",
              },
              {
                title: "How to change text Colour",
                url: "https://help.marketpushapps.com/en/articles/7949356-how-to-change-text-colour",
              },
          ],
        },
      ],
    },
  
    tutorial_videos: {
      show: true,
      videos: [
        {
          title: "How to use Google Sheets Table",
          thumbnail: "https://img.youtube.com/vi/wi_8x_xtJDY/maxresdefault.jpg",
          url: "https://www.youtube.com/watch?v=wi_8x_xtJDY",
        },
      ],
    },
  
    intercom_widget: {
      show: true,
      appName: "Google Sheets Table",
  
      negative_feedback_options: [
        {
          title: "Features do not work for me",
          message:
            "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
        {
          title: "App setup is too complicated",
          message:
            "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
        },
        {
          title: "Subscriptions are too expensive",
          message:
            "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
        },
        {
          title: "App has issues",
          message:
            "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
        },
        {
          title: "Other",
          message: "🥲 Feedback: I have a problem with --> ",
        },
      ],
    },
  };
  