// app id and app is not working
export default {
    app_info: {
        app_name: "pinterest_board",
        app_id: "456d434d-242d-424b-992c-52aa4b6aba9c",
        app_title: "Pinterest Board",
        app_icon: "https://static.wixstatic.com/media/19e924_4d71e0f0c3c441a88f4ea86e8a68f222~mv2.png",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/10351679-how-to-use-pinterest-board",
        review_url: "https://www.wix.com/app-market/add-review/456d434d-242d-424b-992c-52aa4b6aba9c",
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "Enhance your Wix website with the Pinterest Board app, designed to seamlessly embed Pinterest widget for a visually captivating experience. Choose from multiple widget types, including the Board Widget, Pin Widget, Profile Widget, and Follow Button, and tailor their settings to match your site’s design.",
        url: "https://help.marketpushapps.com/en/articles/10351679-how-to-use-pinterest-board",
    },

    tutorial_articles: {
        show: false,
        articles_collections: [{
            collection_name: "Getting Started",
            articles: [
                {
                    title: "1. How to add the app & change the template?",
                    url: "#h_a863a25f52",
                },
                {
                    title: "2. What are the available templates?",
                    url: "#h_6cd334d305",
                },
                {
                    title: "3. Can my customers add a review?",
                    url: "#h_790db58c2a",
                },
                {
                    title: "4. How to modify the content?",
                    url: "#h_74a006b1e6",
                },
                {
                    title: "4. How to add/or remove items",
                    url: "#h_cd9e7a2422",
                },
            ],
        },
        ],
    },

    tutorial_videos: {
        show: true, 
        videos: [{
            title: "How to use Pinterest Board",
            thumbnail: "https://img.youtube.com/vi/jZ7PdYNa62c/maxresdefault.jpg", 
            url: "https://www.youtube.com/watch?v=jZ7PdYNa62c", 
        },],
    },

    intercom_widget: {
        show: true,
        appName: "Pinterest Board",

        negative_feedback_options: [{
            title: "Features do not work for me",
            message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
        {
            title: "App setup is too complicated",
            message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
        },
        {
            title: "Subscriptions are too expensive",
            message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
        },
        {
            title: "App has issues",
            message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
        },
        {
            title: "Other",
            message: "🥲 Feedback: I have a problem with --> ",
        },
        ],
    },
};