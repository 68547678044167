

export default {
  app_info: {
    app_name: "mkp-dev-line-chart",
    app_id: "7d41d962-c979-4868-bc5b-0f10af0d47b5",
    app_title: "Line Chart",
    app_icon:
      "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/dev-mkp-charts-frontend/line-chart-frontend/Logo/Line-Chart-App-Logo.jpg",
  },

  main_links: {
    faq_url:
      "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart",
    review_url:
      "https://www.wix.com/app-market/add-review/7d41d962-c979-4868-bc5b-0f10af0d47b5",
  },

  storage: {
    collection_name: "mkp-dev-line-chart",
  },

  tutorial_main: {
    show: true,
    title: "📘 Getting Started Guide",
    description:
      "The Line Chart Widget is a versatile tool that allows you to create and customize line charts for your website. You can easily configure the chart with different line types and stroke styles, feed data through various methods including API and manual input, and adjust the chart's design to match your site. Follow the detailed guide to get the most out of this powerful widget.",
    url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart",
  },

  tutorial_articles: {
    show: true,
    articles_collections: [
      {
        collection_name: "How to Use Line Chart",
        articles: [
          {
            title: "Step 1: Setting the Type of Line chart",
            url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%201%3A%20Setting%20the%20Type%20of%20Line%20chart",
          },
          {
            title: "Step 2: Setting Lines count and Its data points",
            url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%202%3A%20Setting%20Lines%20count%20and%20Its%20data%20points",
          },
          {
            title: "Step 3: Change the design of the chart",
            url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%203%3A%20Change%20the%20design%20of%20the%20chart",
          },
          {
            title: "Add a Review for the app",
            url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Add%20a%20Review%20for%20the%20app",
          },
        ],
      },
    ],
  },

  tutorial_videos: {
    show: true,
    videos: [
      {
        title: "How to use Line Chart",
        thumbnail: "https://img.youtube.com/vi/TQn9z2ScqqI/maxresdefault.jpg",
        url: "https://youtu.be/TQn9z2ScqqI",
      },
    ],
  },

  intercom_widget: {
    show: true,
    appName: "Line Chart",

    negative_feedback_options: [
      {
        title: "Features do not work for me",
        message:
          "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
      },
      {
        title: "App setup is too complicated",
        message:
          "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
      },
      {
        title: "Subscriptions are too expensive",
        message:
          "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
      },
      {
        title: "App has issues",
        message:
          "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
      },
      {
        title: "Other",
        message: "🥲 Feedback: I have a problem with --> ",
      },
    ],
  },
};
