
export default {
  app_info: {
    app_name: "youtube_gallery",
    app_id: "7541e719-8559-4992-b160-0564cc112c70",
    app_title: "Youtube Gallery",
    app_icon:
      "https://static.wixstatic.com/media/19e924_cc4a0f7bdd904b82b794bc4510156505~mv2.png",
  },

  main_links: {
    faq_url:
      "https://help.marketpushapps.com/en/articles/8729431-youtube-gallery-faq",
    review_url:
      "https://www.wix.com/app-market/add-review/7541e719-8559-4992-b160-0564cc112c70",
  },

  storage: {
    collection_name: "mkp-dev-youtube-gallery",
  },

  tutorial_main: {
    show: true,
    title: "📘 Getting started guide",
    description:
      "This guide will help you to get started with the Youtube Gallery app.",
    url: "https://help.marketpushapps.com/en/articles/8729431-youtube-gallery-faq",
  },

  tutorial_articles: {
    show: true,
    articles_collections: [
      {
        collection_name: "Getting Started",
        articles: [
          {
            title: "1. How to add Youtube Gallery to your site",
            url: "https://help.marketpushapps.com/en/articles/8729431-youtube-gallery-faq#h_b7500b44fc",
          },
          {
            title: "2. How to connect Youtube videos to your Gallery",
            url: "https://help.marketpushapps.com/en/articles/8729431-youtube-gallery-faq#h_8d7e89398b",
          },
          {
            title: "3. How often does the data refresh",
            url: "https://help.marketpushapps.com/en/articles/8729431-youtube-gallery-faq#h_7c1392b13d",
          },
          {
            title:
              "4. I don't want to show likes, comments etc in my Youtube Gallery",
            url: "https://help.marketpushapps.com/en/articles/8729431-youtube-gallery-faq#h_a4555a5ebd",
          },
          {
            title: "5. How can I make videos smaller/bigger",
            url: "https://help.marketpushapps.com/en/articles/8729431-youtube-gallery-faq#h_bb090305d5",
          },
          {
            title: "6. How to make the widget responsive on Mobile and Desktop",
            url: "https://help.marketpushapps.com/en/articles/8729431-youtube-gallery-faq#h_aa179226f1",
          },
          {
            title:
              "7. My Mobile version styles (font etc) modifications are not applied",
            url: "https://help.marketpushapps.com/en/articles/8729431-youtube-gallery-faq#h_d15631d46c",
          },
          {
            title: "8. How do I modify fonts",
            url: "https://help.marketpushapps.com/en/articles/8729431-youtube-gallery-faq#h_842f2f87eb",
          },
          {
            title: "9. How do I modify the background color",
            url: "https://help.marketpushapps.com/en/articles/8729431-youtube-gallery-faq#h_f5ef1a4b94",
          },
          {
            title: "10. How can I add Pagination",
            url: "https://help.marketpushapps.com/en/articles/8729431-youtube-gallery-faq#h_968d54814f",
          },
        ],
      },
    ],
  },

  tutorial_videos: {
    show: true,
    videos: [
      {
        title: "How to add Youtube Video & Channel to Wix Website",
        thumbnail:
          "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/ask-for-review-assets/youtube%20gallery_yt_thumbnail.png",
        description:
          "This video by Market Push Apps shows you how to easily display youtube gallery app on your website.",
        url: "https://youtu.be/YmKP5rIqdMw?t=20",
      },
    ],
  },

  intercom_widget: {
    show: true,
    appName: "Youtube Gallery",

    negative_feedback_options: [
      {
        title: "Features do not work for me",
        message:
          "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
      },
      {
        title: "App setup is too complicated",
        message:
          "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
      },
      {
        title: "Subscriptions are too expensive",
        message:
          "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
      },
      {
        title: "App has issues",
        message:
          "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
      },
      {
        title: "Other",
        message: "🥲 Feedback: I have a problem with --> ",
      },
    ],
  },
};
