
export default {
    app_info: {
      app_name: "events_products_upsell",
      app_id: "3a5a5b36-bc39-4afc-9689-656e3d0d2657",
      app_title: "Events Products Upsell",
      app_icon:
        "https://static.wixstatic.com/media/0904c3_4faff610604b442b9f92596f689db528~mv2.png",
    },
  
    main_links: {
      faq_url:
        "https://help.marketpushapps.com/en/articles/10418226-how-to-use-events-products-upsell",
      review_url:
        "https://www.wix.com/app-market/add-review/3a5a5b36-bc39-4afc-9689-656e3d0d2657",
    },
  
    storage: {
      collection_name: "events_products_upsell",
    },
  
    tutorial_main: {
      show: true,
      title: "Events Products Upsell",
      description:
        "The Events Products Upsell App helps boost revenue by showcasing relevant products alongside your events. Easily customise the recommendations and align the design with your website’s style. Enhance your event experience and drive more sales effortlessly",
      url: "https://help.marketpushapps.com/en/articles/10418226-how-to-use-events-products-upsell",
    },
  
    tutorial_articles: {
      show: false,
      articles_collections: [
        {
          collection_name: "How to Use Line Chart",
          articles: [
            {
              title: "Step 1: Setting the Type of Line chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%201%3A%20Setting%20the%20Type%20of%20Line%20chart",
            },
            {
              title: "Step 2: Setting Lines count and Its data points",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%202%3A%20Setting%20Lines%20count%20and%20Its%20data%20points",
            },
            {
              title: "Step 3: Change the design of the chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%203%3A%20Change%20the%20design%20of%20the%20chart",
            },
            {
              title: "Add a Review for the app",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Add%20a%20Review%20for%20the%20app",
            },
          ],
        },
      ],
    },
  
    tutorial_videos: {
      show: true,
      videos: [
        {
          title: "How to use image hotspots",
          thumbnail: "https://img.youtube.com/vi/MT06SEtIf8Q/maxresdefault.jpg",
          url: "https://www.youtube.com/watch?v=MT06SEtIf8Q",
        },
      ],
    },
  
    intercom_widget: {
      show: true,
      appName: "Events Products Upsell",
  
      negative_feedback_options: [
        {
          title: "Features do not work for me",
          message:
            "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
        {
          title: "App setup is too complicated",
          message:
            "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
        },
        {
          title: "Subscriptions are too expensive",
          message:
            "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
        },
        {
          title: "App has issues",
          message:
            "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
        },
        {
          title: "Other",
          message: "🥲 Feedback: I have a problem with --> ",
        },
      ],
    },
  };
  