export default {
  app_info: {
    app_name: "mkp-dev-bookings-products-cross-sell",
    app_id: "245d0425-8d2f-491f-9812-d80ccc445a98",
    app_title: "Bookings Products Cross Sell",
    app_icon:
      "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/mkp-dev-bookings-products-cross-sell/logo/logo.png",
  },

  main_links: {
    faq_url:
      "https://help.marketpushapps.com/en/articles/10295046-how-to-use-bookings-products-cross-sell",
    review_url:
      "https://www.wix.com/app-market/add-review/245d0425-8d2f-491f-9812-d80ccc445a98",
  },

  storage: {
    collection_name: "mkp-dev-bookings-products-cross-sell",
  },

  tutorial_main: {
    show: true,
    title: "📘 Getting Started Guide",
    description:
      "The Booking Products Cross-Sell App helps boost sales by showcasing relevant products or services alongside bookings. Easily customize recommendations and match the design to your website. Enhance your customer experience and drive conversions effortlessly.",
    url: "https://help.marketpushapps.com/en/articles/10295046-how-to-use-bookings-products-cross-sell",
  },

  tutorial_articles: {
    show: true,
    articles_collections: [
      {
        collection_name: "How to use Bookings Products Cross Sell",
        articles: [
          {
            title: "1. Add Bookings Products Cross-Sell to your website",
            url: "https://help.marketpushapps.com/en/articles/10295046-how-to-use-bookings-products-cross-sell#h_6d8ea1616d",
          },
          {
            title: "2. How to add a new service",
            url: "https://help.marketpushapps.com/en/articles/10295046-how-to-use-bookings-products-cross-sell#h_eddff7ca21",
          },
          {
            title: "3. How to add your products to Service Page",
            url: "https://help.marketpushapps.com/en/articles/10295046-how-to-use-bookings-products-cross-sell#h_7de22ec0dc",
          },
          {
            title: "4. How to customize your widget",
            url: "https://help.marketpushapps.com/en/articles/10295046-how-to-use-bookings-products-cross-sell#h_150946161b",
          },
          {
            title: "5. How to get Additional Support",
            url: "https://help.marketpushapps.com/en/articles/10295046-how-to-use-bookings-products-cross-sell#h_05541ac924",
          },
        ],
      },
     
    ],
  },

  tutorial_videos: {
    show: false,
    videos: [
      {
        title: "How to use image hotspots",
        thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
        url: "https://www.youtube.com/watch?v=123456",
      },
    ],
  },

  intercom_widget: {
    show: true,
    appName: "Bookings Products Cross Sell",

    negative_feedback_options: [
      {
        title: "Features do not work for me",
        message:
          "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
      },
      {
        title: "App setup is too complicated",
        message:
          "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
      },
      {
        title: "Subscriptions are too expensive",
        message:
          "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
      },
      {
        title: "App has issues",
        message:
          "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
      },
      {
        title: "Other",
        message: "🥲 Feedback: I have a problem with --> ",
      },
    ],
  },
};
