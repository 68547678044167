
export default {
    app_info: {
      app_name: "paypal_payments_button",
      app_id: "32bd010e-4f50-41c6-a816-d2ed1dc71c94",
      app_title: "Paypal Payments Button",
      app_icon:
        "https://static.wixstatic.com/media/3b0a1f_25e863301b1e4110bbe478311b261d77~mv2.png",
    },
  
    main_links: {
      faq_url:
        "https://help.marketpushapps.com/en/articles/10748914-how-to-use-paypal-payments-button",
      review_url:
        "https://www.wix.com/app-market/add-review/32bd010e-4f50-41c6-a816-d2ed1dc71c94",
    },
  
    storage: {
      collection_name: "paypal_payments_button",
    },
  
    tutorial_main: {
      show: true,
      title: "📘 Getting Started Guide",
      description:
        "Easily add a PayPal Pay Now button with a simple payment link—no API keys or complex setup required. Customize text and color to match your brand and place it anywhere on your site. This secure solution supports one-time payments for fast transactions but excludes donations and subscriptions, making it ideal for businesses seeking a hassle-free checkout.",
      url: "https://help.marketpushapps.com/en/articles/10748914-how-to-use-paypal-payments-button",
    },
  
    tutorial_articles: {
      show: false,
      articles_collections: [
        {
          collection_name: "How to Use Line Chart",
          articles: [
            {
              title: "Step 1: Setting the Type of Line chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%201%3A%20Setting%20the%20Type%20of%20Line%20chart",
            },
            {
              title: "Step 2: Setting Lines count and Its data points",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%202%3A%20Setting%20Lines%20count%20and%20Its%20data%20points",
            },
            {
              title: "Step 3: Change the design of the chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%203%3A%20Change%20the%20design%20of%20the%20chart",
            },
            {
              title: "Add a Review for the app",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Add%20a%20Review%20for%20the%20app",
            },
          ],
        },
      ],
    },
  
    tutorial_videos: {
      show: false,
      videos: [
        {
          title: "How to use image hotspots",
          thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
          url: "https://www.youtube.com/watch?v=123456",
        },
      ],
    },
  
    intercom_widget: {
      show: true,
      appName: "Paypal Payments Button",
  
      negative_feedback_options: [
        {
          title: "Features do not work for me",
          message:
            "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
        {
          title: "App setup is too complicated",
          message:
            "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
        },
        {
          title: "Subscriptions are too expensive",
          message:
            "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
        },
        {
          title: "App has issues",
          message:
            "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
        },
        {
          title: "Other",
          message: "🥲 Feedback: I have a problem with --> ",
        },
      ],
    },
  };
  