
export default {
    app_info: {
      app_name: "spotify_playlist_embed",
      app_id: "8c9439fe-e0ef-4f1e-8178-bf9edaa17b7b",
      app_title: "Spotify Playlist",
      app_icon:
        "https://static.wixstatic.com/media/0904c3_bf0984d9358a43678c8ccbbde8bdd6b3~mv2.png",
    },
  
    main_links: {
      faq_url:
        "https://help.marketpushapps.com/en/articles/10472906-how-to-use-spotify-playlist",
      review_url:
        "https://www.wix.com/app-market/add-review/8c9439fe-e0ef-4f1e-8178-bf9edaa17b7b",
    },
  
    storage: {
      collection_name: "spotify_playlist_embed",
    },
  
    tutorial_main: {
      show: true,
      title: "📘 Getting Started Guide",
      description:
        "The Spotify Playlist Embed app lets users showcase Spotify playlists on websites effortlessly. Enter a playlist URL to integrate music collections seamlessly. Customize with theme colors, adjustable width, and height settings, and preview changes in real time. Supporting public playlists, the app adds a stylish, engaging touch to personal blogs or business pages, making it a user-friendly solution for enhancing your site with music.",
      url: "https://help.marketpushapps.com/en/articles/10472906-how-to-use-spotify-playlist",
    },
  
    tutorial_articles: {
      show: false,
      articles_collections: [
        {
          collection_name: "How to Use Line Chart",
          articles: [
            {
              title: "Step 1: Setting the Type of Line chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%201%3A%20Setting%20the%20Type%20of%20Line%20chart",
            },
            {
              title: "Step 2: Setting Lines count and Its data points",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%202%3A%20Setting%20Lines%20count%20and%20Its%20data%20points",
            },
            {
              title: "Step 3: Change the design of the chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%203%3A%20Change%20the%20design%20of%20the%20chart",
            },
            {
              title: "Add a Review for the app",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Add%20a%20Review%20for%20the%20app",
            },
          ],
        },
      ],
    },
  
    tutorial_videos: {
      show: false,
      videos: [
        {
          title: "How to use image hotspots",
          thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
          url: "https://www.youtube.com/watch?v=123456",
        },
      ],
    },
  
    intercom_widget: {
      show: true,
      appName: "Spotify Playlist",
  
      negative_feedback_options: [
        {
          title: "Features do not work for me",
          message:
            "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
        {
          title: "App setup is too complicated",
          message:
            "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
        },
        {
          title: "Subscriptions are too expensive",
          message:
            "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
        },
        {
          title: "App has issues",
          message:
            "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
        },
        {
          title: "Other",
          message: "🥲 Feedback: I have a problem with --> ",
        },
      ],
    },
  };
  