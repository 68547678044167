export default {
  app_info: {
    app_name: "quantity_and_volume_discounts",
    app_id: "9574929e-f0d9-40cc-8f69-e9d3b70b7a7c",
    app_title: "Quantity & Volume Discounts",
    app_icon:
      "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/mkp-dev-quantity-and-volumes/Logo/logo.png",
  },

  main_links: {
    faq_url:
      "https://help.marketpushapps.com/en/articles/10295045-how-to-use-quantity-and-volume-discounts",
    review_url:
      "https://www.wix.com/app-market/add-review/9574929e-f0d9-40cc-8f69-e9d3b70b7a7c",
  },

  storage: {
    collection_name: "mkp-dev-quantity-and-volumes",
  },

  tutorial_main: {
    show: true,
    title: "📘 Getting Started Guide",
    description:
      "The Quantity and Volume Discount App drives higher sales by offering tiered discounts based on the quantity or volume of products purchased. Easily configure discount rules and customize the design to fit your website’s style. Enhance customer satisfaction and encourage bulk purchases with this effective sales-boosting tool.",
    url: "https://help.marketpushapps.com/en/articles/10295045-how-to-use-quantity-and-volume-discounts",
  },

  tutorial_articles: {
    show: true,
    articles_collections: [
      {
        collection_name: "How to use Quantity & Volume Discounts",
        articles: [
          {
            title: "1. Add Quantity and Volume Discounts to your website",
            url: "https://help.marketpushapps.com/en/articles/10295045-how-to-use-quantity-and-volume-discounts#h_1850104d07",
          },
          {
            title: "2. How to create Discount Templates and add them to Products",
            url: "https://help.marketpushapps.com/en/articles/10295045-how-to-use-quantity-and-volume-discounts#h_b5c9b87776",
          },
          {
            title: "3. How to Customize your Template Layout",
            url: "https://help.marketpushapps.com/en/articles/10295045-how-to-use-quantity-and-volume-discounts#h_b97c95dfd8",
          },
        ],
      },
      
    ],
  },

  tutorial_videos: {
    show: true,
    videos: [
      {
        title: "How to use Quantity & Volume Discounts",
        thumbnail: "https://img.youtube.com/vi/OZAij0zaUD4/maxresdefault.jpg",
        url: "https://youtu.be/OZAij0zaUD4",
      },
    ],
  },

  intercom_widget: {
    show: true,
    appName: "Quantity & Volume Discounts",

    negative_feedback_options: [
      {
        title: "Features do not work for me",
        message:
          "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
      },
      {
        title: "App setup is too complicated",
        message:
          "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
      },
      {
        title: "Subscriptions are too expensive",
        message:
          "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
      },
      {
        title: "App has issues",
        message:
          "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
      },
      {
        title: "Other",
        message: "🥲 Feedback: I have a problem with --> ",
      },
    ],
  },
};
