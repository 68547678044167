
export default {
    app_info: {
        app_name: "nutrition-label",
        app_id: "d6a9f828-4a68-46a9-bfe9-c81a307666e5",
        app_title: "Nutrition Label",
        app_icon: "https://static.wixstatic.com/media/33caa7_660dcc5a91ab43bb99e396be0778bba4~mv2.png/v1/fill/w_54,h_54,al_c,q_85,usm_0.66_1.00_0.01/33caa7_660dcc5a91ab43bb99e396be0778bba4~mv2.webp", // TBC
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/8962533-how-to-use-nutrition-label",
        review_url: "https://www.wix.com/app-market/add-review/d6a9f828-4a68-46a9-bfe9-c81a307666e5",
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "The Nutrition Label app simplifies generating FDA-approved US and UK nutrition labels for your site. Customize labels effortlessly with a user-friendly editor, enabling text edits, translations, and design tweaks to match your brand. Display only relevant labels with flexible options, ensuring a seamless user experience. Translate labels to reach diverse audiences and provide accessible nutritional information. Ensure compliance and precision while enhancing your site’s aesthetics with this versatile tool.",
        url: "https://help.marketpushapps.com/en/articles/8962533-how-to-use-nutrition-label",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
            collection_name: "Getting Started",
            articles: [
                {
                    title: "1. How to add Nutrititon Label",
                    url: "https://help.marketpushapps.com/en/articles/8962533-how-to-use-nutrition-label#h_23c1d9d276",
                },
                {
                    title: "2. How to duplicate an existing widget",
                    url: "https://help.marketpushapps.com/en/articles/8962533-how-to-use-nutrition-label#h_aa00289a0c",
                },
                {
                    title: "3. How to change the values within the Label",
                    url: "https://help.marketpushapps.com/en/articles/8962533-how-to-use-nutrition-label#h_8082fe9675",
                },
                {
                    title: "4. How to translate labels in a different language",
                    url: "https://help.marketpushapps.com/en/articles/8962533-how-to-use-nutrition-label#h_f04b65ed6b",
                },
                {
                    title: "5. How to show / hide values from the Label",
                    url: "https://help.marketpushapps.com/en/articles/8962533-how-to-use-nutrition-label#h_eae4c934e8",
                },
                {
                    title: "6. How to change design & colors",
                    url: "https://help.marketpushapps.com/en/articles/8962533-how-to-use-nutrition-label#h_0d90527ca9",
                },

            ],
        },

        ],
    },

    tutorial_videos: {
        show: false,
        videos: [{
            title: "How to use Nutrition Label",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
            url: "https://www.youtube.com/watch?v=123456",
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Nutrition Label",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};