export default {
    app_info: {
        app_name: "animation_section",
        app_id: "5d12b726-4d7d-4484-acca-7dee550c19af",
        app_title: "Animation Section",
        app_icon: "https://static.wixstatic.com/media/0904c3_60a7f9ab79d644c4ad17989e2fa49a09~mv2.jpg",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/10019610-how-to-use-animation-section",
        review_url: "https://www.wix.com/app-market/add-review/5d12b726-4d7d-4484-acca-7dee550c19af",
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "Animation Section offers you ten ready-to-use, beautifully animated templates to enhance your website's visual appeal. These templates are designed to make your site stand out with professional and engaging animations. Easy to use, with customizable elements in each template, ensuring your website has a dynamic and visually appealing experience with minimal effort.",
        url: "https://help.marketpushapps.com/en/articles/10019610-how-to-use-animation-section",
    },

    tutorial_articles: {
        show: false,
        articles_collections: [{
                collection_name: "Getting Started",
                articles: [
                    {
                        title: "1. How to add the app & change the template?",
                        url: "https://help.marketpushapps.com/en/articles/10019610-how-to-use-animation-section#h_a863a25f52",
                    },
                    {
                        title: "2. What are the available templates?",
                        url: "https://help.marketpushapps.com/en/articles/10019610-how-to-use-animation-section#h_6cd334d305",
                    },
                    {
                        title: "3. Can my customers add a review?",
                        url: "https://help.marketpushapps.com/en/articles/10019610-how-to-use-animation-section#h_790db58c2a",
                    },
                    {
                        title: "4. How to modify the content?",
                        url: "https://help.marketpushapps.com/en/articles/10019610-how-to-use-animation-section#h_74a006b1e6",
                    },
                    {
                        title: "4. How to add/or remove items",
                        url: "https://help.marketpushapps.com/en/articles/10019610-how-to-use-animation-section#h_cd9e7a2422",
                    },
                ],
            },
        ],
    },

    tutorial_videos: {
        show: false, 
        videos: [{
            title: "How to use Animation Section",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png", 
            url: "https://www.youtube.com/watch?v=123456", 
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Animation Section",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
            },
            {
                title: "App setup is too complicated",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
            },
            {
                title: "App has issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};