
export default {
    app_info: {
      app_name: "tiktok_embed",
      app_id: "8b99f1cd-d919-4133-b1d2-c1b2b207527e",
      app_title: "TikTok Embed",
      app_icon:
        "https://static.wixstatic.com/media/c7fe21_67a68fddfd0f4232bad3bbb4fab0974f~mv2.png",
    },
  
    main_links: {
      faq_url:
        "https://help.marketpushapps.com/en/articles/9257012-how-to-use-tiktok-embed",
      review_url:
        "https://www.wix.com/app-market/add-review/8b99f1cd-d919-4133-b1d2-c1b2b207527e",
    },
  
    storage: {
      collection_name: "tiktok_embed",
    },
  
    tutorial_main: {
      show: true,
      title: "📘 Getting Started Guide",
      description:
        "The TikTok Embed app makes adding TikTok content to your site effortless. Embed single videos, full profiles, or curated playlists by hashtags or sounds to keep your site dynamic and engaging. Perfect for showcasing influencers, promoting trends, or featuring thematic compilations, this app enhances user interaction and time spent on your site. Ideal for marketers, creators, and businesses, it brings TikTok's viral charm directly to your audience.",
      url: "https://help.marketpushapps.com/en/articles/9257012-how-to-use-tiktok-embed",
    },
  
    tutorial_articles: {
      show: false,
      articles_collections: [
        {
          collection_name: "How to Use Line Chart",
          articles: [
            {
              title: "Step 1: Setting the Type of Line chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%201%3A%20Setting%20the%20Type%20of%20Line%20chart",
            },
            {
              title: "Step 2: Setting Lines count and Its data points",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%202%3A%20Setting%20Lines%20count%20and%20Its%20data%20points",
            },
            {
              title: "Step 3: Change the design of the chart",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Step%203%3A%20Change%20the%20design%20of%20the%20chart",
            },
            {
              title: "Add a Review for the app",
              url: "https://help.marketpushapps.com/en/articles/9361088-how-to-use-line-chart#:~:text=Add%20a%20Review%20for%20the%20app",
            },
          ],
        },
      ],
    },
  
    tutorial_videos: {
      show: false,
      videos: [
        {
          title: "How to use image hotspots",
          thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
          url: "https://www.youtube.com/watch?v=123456",
        },
      ],
    },
  
    intercom_widget: {
      show: true,
      appName: "TikTok Embed",
  
      negative_feedback_options: [
        {
          title: "Features do not work for me",
          message:
            "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
        {
          title: "App setup is too complicated",
          message:
            "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
        },
        {
          title: "Subscriptions are too expensive",
          message:
            "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
        },
        {
          title: "App has issues",
          message:
            "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
        },
        {
          title: "Other",
          message: "🥲 Feedback: I have a problem with --> ",
        },
      ],
    },
  };
  