export default {
    app_info: {
        app_name: "pdf_and_file_downloads",
        app_id: "28f1e419-2f66-4358-bb61-4238d1e8e252",
        app_title: "Product PDF & File Downloads",
        app_icon: "https://static.wixstatic.com/media/fa5794_f5f390a76bb2463fa3a13397c456cc9d~mv2.png",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/10212225-how-to-use-product-pdf-and-file-download",
        review_url: "https://www.wix.com/app-market/add-review/28f1e419-2f66-4358-bb61-4238d1e8e252",
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "Product PDF & File Downloads make it effortless to showcase and manage downloadable files on your website. With multiple templates and two elegant design styles—color badges or minimalist black-and-white badges—you can display up to 20 file formats with customizable icons and allow users to download files instantly with a single click. Tailor the look by adjusting colors, titles, and toggling file titles or extensions to fit your branding needs. Built with a responsive design, the app ensures seamless functionality across all devices, offering a clean, professional, and user-friendly file management experience.",
        url: "https://help.marketpushapps.com/en/articles/10212225-how-to-use-product-pdf-and-file-download",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
            collection_name: "Getting Started",
            articles: [
                {
                    title: "1. Add Product PDF and File Download to your website",
                    url: "https://help.marketpushapps.com/en/articles/10212225-how-to-use-product-pdf-and-file-download#h_acf914d438",
                },
                {
                    title: "2. How to Upload files for your products",
                    url: "https://help.marketpushapps.com/en/articles/10212225-how-to-use-product-pdf-and-file-download#h_495100c11d",
                },
                {
                    title: "3. How to customize your widget ",
                    url: "https://help.marketpushapps.com/en/articles/10212225-how-to-use-product-pdf-and-file-download#h_874057fdcc",
                },
                {
                    title: "4. How to get Additional Support",
                    url: "https://help.marketpushapps.com/en/articles/10212225-how-to-use-product-pdf-and-file-download#h_d243df4974",
                },
            ],
        },
        ],
    },

    tutorial_videos: {
        show: true, 
        videos: [{
            title: "How to use Product PDF & File Downloads",
            thumbnail: "https://img.youtube.com/vi/5X8CoHpBZSU/maxresdefault.jpg", 
            url: "https://youtu.be/5X8CoHpBZSU?si=D8kDWnokOHJIWmIU", 
        },],
    },

    intercom_widget: {
        show: true,
        appName: "Product PDF & File Downloads",

        negative_feedback_options: [{
            title: "Features do not work for me",
            message: "🥲 Feedback: Some features are not working for me, I am providing more info here --> ",
        },
        {
            title: "App setup is too complicated",
            message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
        },
        {
            title: "Subscriptions are too expensive",
            message: "🥲 Feedback: Subscriptions are expensive, better to have a plan like --> ",
        },
        {
            title: "App has issues",
            message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
        },
        {
            title: "Other",
            message: "🥲 Feedback: I have a problem with --> ",
        },
        ],
    },
};