
export default {
    app_info: {
        app_name: "hero-templates",
        app_id: "42a250cf-6010-4093-ace4-f72e77093ef5",
        app_title: "Hero Templates",
        app_icon: "https://dev-reviews-mkp.nyc3.cdn.digitaloceanspaces.com/dev-mkp-hero-templates/logo/mkp-hero-template-logo-app.png",
    },

    main_links: {
        faq_url: "https://help.marketpushapps.com/en/articles/8609354-hero-templates-faq",
        review_url: "https://www.wix.com/app-market/add-review/42a250cf-6010-4093-ace4-f72e77093ef5",
    },

    tutorial_main: {
        show: true,
        title: "📘 Getting started guide",
        description: "The Hero Templates app allows users to effortlessly add a visually striking hero section to their homepage without coding. With 17 unique templates, businesses can choose a design that best reflects their brand's identity. Every aspect, from colors to layout, is fully customizable for a seamless integration into any website. Designed to create a strong first impression, it enhances visual appeal and engagement with a simple, intuitive setup.",
        url: "https://help.marketpushapps.com/en/articles/8609354-hero-templates-faq",
    },

    tutorial_articles: {
        show: true,
        articles_collections: [{
            collection_name: "Getting Started",
            articles: [
                {
                    title: "1. What is a Hero Section?",
                    url: "https://help.marketpushapps.com/en/articles/8609354-hero-templates-faq#h_55a65252a1",
                },
                {
                    title: "2. How use the Hero Template app?",
                    url: "https://help.marketpushapps.com/en/articles/8609354-hero-templates-faq#h_7c5736351c",
                },
                {
                    title: "3. How to remove the watermark branding?",
                    url: "https://help.marketpushapps.com/en/articles/8609354-hero-templates-faq#h_2f97280610",
                },
                {
                    title: "4. How do I change images/texts?",
                    url: "https://help.marketpushapps.com/en/articles/8609354-hero-templates-faq#h_2fe678acb4",
                },

            ],
        },

        ],
    },

    tutorial_videos: {
        show: false,
        videos: [{
            title: "How to use Hero Templates",
            thumbnail: "https://www.marketpushapps.com/images/image-hotspots.png",
            url: "https://www.youtube.com/watch?v=123456",
        }, ],
    },

    intercom_widget: {
        show: true,
        appName: "Hero Templates",

        negative_feedback_options: [{
                title: "Features do not work for me",
                message: "🥲 Feedback: Some features are not working for me, that is --> ",
            },
            {
                title: "User flow is more complex",
                message: "🥲 Feedback: The user flow is too complicated for me, I had a hard time finding --> ",
            },
            {
                title: "Subscriptions are too expensive",
                message: "🥲 Feedback: Subscriptions are expensive, it would be better to have a plan like --> ",
            },
            {
                title: "Widgets have some issues",
                message: "🥲 Feedback: There are some issues with the widget, I had a problem with --> ",
            },
            {
                title: "Other",
                message: "🥲 Feedback: I have a problem with --> ",
            },
        ],
    },
};